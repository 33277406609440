
type Languages = 'de-DE' | 'en-US' | 'es-ES' | 'fr-FR' | 'it-IT' | 'ja-JP' | 'ko-KR' | 'pl-PL' | 'pt-BR' | 'pt-PT' | 'ro-RO' | 'ru-RU' | 'zh-CN' | 'zh-TW';
type Sections = 'Routes' | 'Footer' | 'Header' | 'MegaMenu' | 'AllCategoriesExpandedMenu' | 'MiniCart' | 'Language' | 'productDetail' | 'AddressBook' | 'Cart' | 'CheckoutAddress' | 'Checkout' | 'Payment' | 'MultipleShipmentTable' | 'Shipping' | 'OrderConfirmation' | 'SignInPage' | 'Account' | 'AccountSummary' | 'ChangePasswordSection' | 'PersonalInformationSection' | 'WelcomeUserSection' | 'AdminTools' | 'BuyerManagement' | 'BuyerOrganizationAdminTools' | 'ApprovalsManagement' | 'BuyerApprovalDetails' | 'OrderApprovalDetails' | 'OrganizationManagement' | 'BuyerOrganizationRegistration' | 'BuyerUserRegistration' | 'Dashboard' | 'Order' | 'Category' | 'AccountLinksGridView' | 'AccountLinks' | 'MyAccount' | 'AddressCard' | 'AddressForm' | 'ChangePassword' | 'CheckoutProfile' | 'ChildPimCategories' | 'Confirmation' | 'FeaturedCard' | 'ForgotPassword' | 'PriceDisplay' | 'error-message' | 'success-message' | 'OrderBillingInfo' | 'OrderDetails' | 'OrderDiscountSummary' | 'OrderItemTable' | 'OrderLinks' | 'OrderPaymentInfo' | 'OrderShippingInfo' | 'OrderTotalSummary' | 'PaymentMethodContainer' | 'PaymentInfoCard' | 'PaymentInfoList' | 'PaymentMethodSelection' | 'PersonalInformation' | 'ProductFilter' | 'ProductGrid' | 'PurchaseOrderNumber' | 'PurchaseOrderSelection' | 'RecurringOrderInfo' | 'RegistrationLayout' | 'RegistrationB2BLayout' | 'ResetPassword' | 'SearchBar' | 'SessionError' | 'SignIn' | 'Pickup' | 'CommerceEnvironment' | 'RequisitionLists' | 'RequisitionListItems' | 'InprogressOrders' | 'InprogressItems' | 'commonTable' | 'WishList' | 'OrderMethod' | 'compare' | 'sellers' | 'StoreLocator' | 'ProgressIndicator' | 'Files' | 'PasswordInput' | 'Common';
export type Translation = Record<string, string | MixedObj>;
interface MixedObj extends Translation {}
type ArgTypes = string | number;
type TemplateArgs = Record<string, ArgTypes>;

const manifest: Record<
    Languages | string,
    Record<Sections | string, () => Promise<Translation>>
> = {
    'de-DE': {
        'Routes': () => import('./de-DE/Routes'),
        'Footer': () => import('./de-DE/Footer'),
        'Header': () => import('./de-DE/Header'),
        'MegaMenu': () => import('./de-DE/MegaMenu'),
        'AllCategoriesExpandedMenu': () => import('./de-DE/AllCategoriesExpandedMenu'),
        'MiniCart': () => import('./de-DE/MiniCart'),
        'Language': () => import('./de-DE/Language'),
        'productDetail': () => import('./de-DE/productDetail'),
        'AddressBook': () => import('./de-DE/AddressBook'),
        'Cart': () => import('./de-DE/Cart'),
        'CheckoutAddress': () => import('./de-DE/CheckoutAddress'),
        'Checkout': () => import('./de-DE/Checkout'),
        'Payment': () => import('./de-DE/Payment'),
        'MultipleShipmentTable': () => import('./de-DE/MultipleShipmentTable'),
        'Shipping': () => import('./de-DE/Shipping'),
        'OrderConfirmation': () => import('./de-DE/OrderConfirmation'),
        'SignInPage': () => import('./de-DE/SignInPage'),
        'Account': () => import('./de-DE/Account'),
        'AccountSummary': () => import('./de-DE/AccountSummary'),
        'ChangePasswordSection': () => import('./de-DE/ChangePasswordSection'),
        'PersonalInformationSection': () => import('./de-DE/PersonalInformationSection'),
        'WelcomeUserSection': () => import('./de-DE/WelcomeUserSection'),
        'AdminTools': () => import('./de-DE/AdminTools'),
        'BuyerOrganizationAdminTools': () => import('./de-DE/BuyerOrganizationAdminTools'),
        'ApprovalsManagement': () => import('./de-DE/ApprovalsManagement'),
        'BuyerOrganizationRegistration': () => import('./de-DE/BuyerOrganizationRegistration'),
        'BuyerUserRegistration': () => import('./de-DE/BuyerUserRegistration'),
        'Dashboard': () => import('./de-DE/Dashboard'),
        'Order': () => import('./de-DE/Order'),
        'Category': () => import('./de-DE/Category'),
        'AccountLinksGridView': () => import('./de-DE/AccountLinksGridView'),
        'AccountLinks': () => import('./de-DE/AccountLinks'),
        'MyAccount': () => import('./de-DE/MyAccount'),
        'AddressCard': () => import('./de-DE/AddressCard'),
        'AddressForm': () => import('./de-DE/AddressForm'),
        'ChangePassword': () => import('./de-DE/ChangePassword'),
        'CheckoutProfile': () => import('./de-DE/CheckoutProfile'),
        'ChildPimCategories': () => import('./de-DE/ChildPimCategories'),
        'Confirmation': () => import('./de-DE/Confirmation'),
        'FeaturedCard': () => import('./de-DE/FeaturedCard'),
        'ForgotPassword': () => import('./de-DE/ForgotPassword'),
        'PriceDisplay': () => import('./de-DE/PriceDisplay'),
        'error-message': () => import('./de-DE/error-message'),
        'success-message': () => import('./de-DE/success-message'),
        'OrderBillingInfo': () => import('./de-DE/OrderBillingInfo'),
        'OrderDetails': () => import('./de-DE/OrderDetails'),
        'OrderDiscountSummary': () => import('./de-DE/OrderDiscountSummary'),
        'OrderItemTable': () => import('./de-DE/OrderItemTable'),
        'OrderLinks': () => import('./de-DE/OrderLinks'),
        'OrderPaymentInfo': () => import('./de-DE/OrderPaymentInfo'),
        'OrderShippingInfo': () => import('./de-DE/OrderShippingInfo'),
        'OrderTotalSummary': () => import('./de-DE/OrderTotalSummary'),
        'PaymentMethodContainer': () => import('./de-DE/PaymentMethodContainer'),
        'PaymentInfoCard': () => import('./de-DE/PaymentInfoCard'),
        'PaymentInfoList': () => import('./de-DE/PaymentInfoList'),
        'PaymentMethodSelection': () => import('./de-DE/PaymentMethodSelection'),
        'PersonalInformation': () => import('./de-DE/PersonalInformation'),
        'ProductFilter': () => import('./de-DE/ProductFilter'),
        'ProductGrid': () => import('./de-DE/ProductGrid'),
        'PurchaseOrderNumber': () => import('./de-DE/PurchaseOrderNumber'),
        'PurchaseOrderSelection': () => import('./de-DE/PurchaseOrderSelection'),
        'RecurringOrderInfo': () => import('./de-DE/RecurringOrderInfo'),
        'RegistrationLayout': () => import('./de-DE/RegistrationLayout'),
        'RegistrationB2BLayout': () => import('./de-DE/RegistrationB2BLayout'),
        'ResetPassword': () => import('./de-DE/ResetPassword'),
        'SearchBar': () => import('./de-DE/SearchBar'),
        'SessionError': () => import('./de-DE/SessionError'),
        'SignIn': () => import('./de-DE/SignIn'),
        'Pickup': () => import('./de-DE/Pickup'),
        'CommerceEnvironment': () => import('./de-DE/CommerceEnvironment'),
        'RequisitionLists': () => import('./de-DE/RequisitionLists'),
        'RequisitionListItems': () => import('./de-DE/RequisitionListItems'),
        'InprogressOrders': () => import('./de-DE/InprogressOrders'),
        'InprogressItems': () => import('./de-DE/InprogressItems'),
        'commonTable': () => import('./de-DE/commonTable'),
        'WishList': () => import('./de-DE/WishList'),
        'OrderMethod': () => import('./de-DE/OrderMethod'),
        'compare': () => import('./de-DE/compare'),
        'sellers': () => import('./de-DE/sellers'),
        'StoreLocator': () => import('./de-DE/StoreLocator'),
        'ProgressIndicator': () => import('./de-DE/ProgressIndicator'),
        'Files': () => import('./de-DE/Files'),
        'PasswordInput': () => import('./de-DE/PasswordInput'),
        'Common': () => import('./de-DE/Common'),
    },
    'en-US': {
        'Routes': () => import('./en-US/Routes'),
        'Footer': () => import('./en-US/Footer'),
        'Header': () => import('./en-US/Header'),
        'MegaMenu': () => import('./en-US/MegaMenu'),
        'AllCategoriesExpandedMenu': () => import('./en-US/AllCategoriesExpandedMenu'),
        'MiniCart': () => import('./en-US/MiniCart'),
        'Language': () => import('./en-US/Language'),
        'productDetail': () => import('./en-US/productDetail'),
        'AddressBook': () => import('./en-US/AddressBook'),
        'Cart': () => import('./en-US/Cart'),
        'CheckoutAddress': () => import('./en-US/CheckoutAddress'),
        'Checkout': () => import('./en-US/Checkout'),
        'Payment': () => import('./en-US/Payment'),
        'MultipleShipmentTable': () => import('./en-US/MultipleShipmentTable'),
        'Shipping': () => import('./en-US/Shipping'),
        'OrderConfirmation': () => import('./en-US/OrderConfirmation'),
        'SignInPage': () => import('./en-US/SignInPage'),
        'Account': () => import('./en-US/Account'),
        'AccountSummary': () => import('./en-US/AccountSummary'),
        'ChangePasswordSection': () => import('./en-US/ChangePasswordSection'),
        'PersonalInformationSection': () => import('./en-US/PersonalInformationSection'),
        'WelcomeUserSection': () => import('./en-US/WelcomeUserSection'),
        'AdminTools': () => import('./en-US/AdminTools'),
        'BuyerManagement': () => import('./en-US/BuyerManagement'),
        'BuyerOrganizationAdminTools': () => import('./en-US/BuyerOrganizationAdminTools'),
        'ApprovalsManagement': () => import('./en-US/ApprovalsManagement'),
        'BuyerApprovalDetails': () => import('./en-US/BuyerApprovalDetails'),
        'OrderApprovalDetails': () => import('./en-US/OrderApprovalDetails'),
        'OrganizationManagement': () => import('./en-US/OrganizationManagement'),
        'BuyerOrganizationRegistration': () => import('./en-US/BuyerOrganizationRegistration'),
        'BuyerUserRegistration': () => import('./en-US/BuyerUserRegistration'),
        'Dashboard': () => import('./en-US/Dashboard'),
        'Order': () => import('./en-US/Order'),
        'Category': () => import('./en-US/Category'),
        'AccountLinksGridView': () => import('./en-US/AccountLinksGridView'),
        'AccountLinks': () => import('./en-US/AccountLinks'),
        'MyAccount': () => import('./en-US/MyAccount'),
        'AddressCard': () => import('./en-US/AddressCard'),
        'AddressForm': () => import('./en-US/AddressForm'),
        'ChangePassword': () => import('./en-US/ChangePassword'),
        'CheckoutProfile': () => import('./en-US/CheckoutProfile'),
        'ChildPimCategories': () => import('./en-US/ChildPimCategories'),
        'Confirmation': () => import('./en-US/Confirmation'),
        'FeaturedCard': () => import('./en-US/FeaturedCard'),
        'ForgotPassword': () => import('./en-US/ForgotPassword'),
        'PriceDisplay': () => import('./en-US/PriceDisplay'),
        'error-message': () => import('./en-US/error-message'),
        'success-message': () => import('./en-US/success-message'),
        'OrderBillingInfo': () => import('./en-US/OrderBillingInfo'),
        'OrderDetails': () => import('./en-US/OrderDetails'),
        'OrderDiscountSummary': () => import('./en-US/OrderDiscountSummary'),
        'OrderItemTable': () => import('./en-US/OrderItemTable'),
        'OrderLinks': () => import('./en-US/OrderLinks'),
        'OrderPaymentInfo': () => import('./en-US/OrderPaymentInfo'),
        'OrderShippingInfo': () => import('./en-US/OrderShippingInfo'),
        'OrderTotalSummary': () => import('./en-US/OrderTotalSummary'),
        'PaymentMethodContainer': () => import('./en-US/PaymentMethodContainer'),
        'PaymentInfoCard': () => import('./en-US/PaymentInfoCard'),
        'PaymentInfoList': () => import('./en-US/PaymentInfoList'),
        'PaymentMethodSelection': () => import('./en-US/PaymentMethodSelection'),
        'PersonalInformation': () => import('./en-US/PersonalInformation'),
        'ProductFilter': () => import('./en-US/ProductFilter'),
        'ProductGrid': () => import('./en-US/ProductGrid'),
        'PurchaseOrderNumber': () => import('./en-US/PurchaseOrderNumber'),
        'PurchaseOrderSelection': () => import('./en-US/PurchaseOrderSelection'),
        'RecurringOrderInfo': () => import('./en-US/RecurringOrderInfo'),
        'RegistrationLayout': () => import('./en-US/RegistrationLayout'),
        'RegistrationB2BLayout': () => import('./en-US/RegistrationB2BLayout'),
        'ResetPassword': () => import('./en-US/ResetPassword'),
        'SearchBar': () => import('./en-US/SearchBar'),
        'SessionError': () => import('./en-US/SessionError'),
        'SignIn': () => import('./en-US/SignIn'),
        'Pickup': () => import('./en-US/Pickup'),
        'CommerceEnvironment': () => import('./en-US/CommerceEnvironment'),
        'RequisitionLists': () => import('./en-US/RequisitionLists'),
        'RequisitionListItems': () => import('./en-US/RequisitionListItems'),
        'InprogressOrders': () => import('./en-US/InprogressOrders'),
        'InprogressItems': () => import('./en-US/InprogressItems'),
        'commonTable': () => import('./en-US/commonTable'),
        'WishList': () => import('./en-US/WishList'),
        'OrderMethod': () => import('./en-US/OrderMethod'),
        'compare': () => import('./en-US/compare'),
        'sellers': () => import('./en-US/sellers'),
        'StoreLocator': () => import('./en-US/StoreLocator'),
        'ProgressIndicator': () => import('./en-US/ProgressIndicator'),
        'Files': () => import('./en-US/Files'),
        'PasswordInput': () => import('./en-US/PasswordInput'),
        'Common': () => import('./en-US/Common'),
    },
    'es-ES': {
        'Routes': () => import('./es-ES/Routes'),
        'Footer': () => import('./es-ES/Footer'),
        'Header': () => import('./es-ES/Header'),
        'MegaMenu': () => import('./es-ES/MegaMenu'),
        'AllCategoriesExpandedMenu': () => import('./es-ES/AllCategoriesExpandedMenu'),
        'MiniCart': () => import('./es-ES/MiniCart'),
        'Language': () => import('./es-ES/Language'),
        'productDetail': () => import('./es-ES/productDetail'),
        'AddressBook': () => import('./es-ES/AddressBook'),
        'Cart': () => import('./es-ES/Cart'),
        'CheckoutAddress': () => import('./es-ES/CheckoutAddress'),
        'Checkout': () => import('./es-ES/Checkout'),
        'Payment': () => import('./es-ES/Payment'),
        'MultipleShipmentTable': () => import('./es-ES/MultipleShipmentTable'),
        'Shipping': () => import('./es-ES/Shipping'),
        'OrderConfirmation': () => import('./es-ES/OrderConfirmation'),
        'SignInPage': () => import('./es-ES/SignInPage'),
        'Account': () => import('./es-ES/Account'),
        'AccountSummary': () => import('./es-ES/AccountSummary'),
        'ChangePasswordSection': () => import('./es-ES/ChangePasswordSection'),
        'PersonalInformationSection': () => import('./es-ES/PersonalInformationSection'),
        'WelcomeUserSection': () => import('./es-ES/WelcomeUserSection'),
        'AdminTools': () => import('./es-ES/AdminTools'),
        'BuyerOrganizationAdminTools': () => import('./es-ES/BuyerOrganizationAdminTools'),
        'ApprovalsManagement': () => import('./es-ES/ApprovalsManagement'),
        'BuyerOrganizationRegistration': () => import('./es-ES/BuyerOrganizationRegistration'),
        'BuyerUserRegistration': () => import('./es-ES/BuyerUserRegistration'),
        'Dashboard': () => import('./es-ES/Dashboard'),
        'Order': () => import('./es-ES/Order'),
        'Category': () => import('./es-ES/Category'),
        'AccountLinksGridView': () => import('./es-ES/AccountLinksGridView'),
        'AccountLinks': () => import('./es-ES/AccountLinks'),
        'MyAccount': () => import('./es-ES/MyAccount'),
        'AddressCard': () => import('./es-ES/AddressCard'),
        'AddressForm': () => import('./es-ES/AddressForm'),
        'ChangePassword': () => import('./es-ES/ChangePassword'),
        'CheckoutProfile': () => import('./es-ES/CheckoutProfile'),
        'ChildPimCategories': () => import('./es-ES/ChildPimCategories'),
        'Confirmation': () => import('./es-ES/Confirmation'),
        'FeaturedCard': () => import('./es-ES/FeaturedCard'),
        'ForgotPassword': () => import('./es-ES/ForgotPassword'),
        'PriceDisplay': () => import('./es-ES/PriceDisplay'),
        'error-message': () => import('./es-ES/error-message'),
        'success-message': () => import('./es-ES/success-message'),
        'OrderBillingInfo': () => import('./es-ES/OrderBillingInfo'),
        'OrderDetails': () => import('./es-ES/OrderDetails'),
        'OrderDiscountSummary': () => import('./es-ES/OrderDiscountSummary'),
        'OrderItemTable': () => import('./es-ES/OrderItemTable'),
        'OrderLinks': () => import('./es-ES/OrderLinks'),
        'OrderPaymentInfo': () => import('./es-ES/OrderPaymentInfo'),
        'OrderShippingInfo': () => import('./es-ES/OrderShippingInfo'),
        'OrderTotalSummary': () => import('./es-ES/OrderTotalSummary'),
        'PaymentMethodContainer': () => import('./es-ES/PaymentMethodContainer'),
        'PaymentInfoCard': () => import('./es-ES/PaymentInfoCard'),
        'PaymentInfoList': () => import('./es-ES/PaymentInfoList'),
        'PaymentMethodSelection': () => import('./es-ES/PaymentMethodSelection'),
        'PersonalInformation': () => import('./es-ES/PersonalInformation'),
        'ProductFilter': () => import('./es-ES/ProductFilter'),
        'ProductGrid': () => import('./es-ES/ProductGrid'),
        'PurchaseOrderNumber': () => import('./es-ES/PurchaseOrderNumber'),
        'PurchaseOrderSelection': () => import('./es-ES/PurchaseOrderSelection'),
        'RecurringOrderInfo': () => import('./es-ES/RecurringOrderInfo'),
        'RegistrationLayout': () => import('./es-ES/RegistrationLayout'),
        'RegistrationB2BLayout': () => import('./es-ES/RegistrationB2BLayout'),
        'ResetPassword': () => import('./es-ES/ResetPassword'),
        'SearchBar': () => import('./es-ES/SearchBar'),
        'SessionError': () => import('./es-ES/SessionError'),
        'SignIn': () => import('./es-ES/SignIn'),
        'Pickup': () => import('./es-ES/Pickup'),
        'CommerceEnvironment': () => import('./es-ES/CommerceEnvironment'),
        'RequisitionLists': () => import('./es-ES/RequisitionLists'),
        'RequisitionListItems': () => import('./es-ES/RequisitionListItems'),
        'InprogressOrders': () => import('./es-ES/InprogressOrders'),
        'InprogressItems': () => import('./es-ES/InprogressItems'),
        'commonTable': () => import('./es-ES/commonTable'),
        'WishList': () => import('./es-ES/WishList'),
        'OrderMethod': () => import('./es-ES/OrderMethod'),
        'compare': () => import('./es-ES/compare'),
        'sellers': () => import('./es-ES/sellers'),
        'StoreLocator': () => import('./es-ES/StoreLocator'),
        'ProgressIndicator': () => import('./es-ES/ProgressIndicator'),
        'Files': () => import('./es-ES/Files'),
        'PasswordInput': () => import('./es-ES/PasswordInput'),
        'Common': () => import('./es-ES/Common'),
    },
    'fr-FR': {
        'Routes': () => import('./fr-FR/Routes'),
        'Footer': () => import('./fr-FR/Footer'),
        'Header': () => import('./fr-FR/Header'),
        'MegaMenu': () => import('./fr-FR/MegaMenu'),
        'AllCategoriesExpandedMenu': () => import('./fr-FR/AllCategoriesExpandedMenu'),
        'MiniCart': () => import('./fr-FR/MiniCart'),
        'Language': () => import('./fr-FR/Language'),
        'productDetail': () => import('./fr-FR/productDetail'),
        'AddressBook': () => import('./fr-FR/AddressBook'),
        'Cart': () => import('./fr-FR/Cart'),
        'CheckoutAddress': () => import('./fr-FR/CheckoutAddress'),
        'Checkout': () => import('./fr-FR/Checkout'),
        'Payment': () => import('./fr-FR/Payment'),
        'MultipleShipmentTable': () => import('./fr-FR/MultipleShipmentTable'),
        'Shipping': () => import('./fr-FR/Shipping'),
        'OrderConfirmation': () => import('./fr-FR/OrderConfirmation'),
        'SignInPage': () => import('./fr-FR/SignInPage'),
        'Account': () => import('./fr-FR/Account'),
        'AccountSummary': () => import('./fr-FR/AccountSummary'),
        'ChangePasswordSection': () => import('./fr-FR/ChangePasswordSection'),
        'PersonalInformationSection': () => import('./fr-FR/PersonalInformationSection'),
        'WelcomeUserSection': () => import('./fr-FR/WelcomeUserSection'),
        'AdminTools': () => import('./fr-FR/AdminTools'),
        'BuyerOrganizationAdminTools': () => import('./fr-FR/BuyerOrganizationAdminTools'),
        'ApprovalsManagement': () => import('./fr-FR/ApprovalsManagement'),
        'BuyerOrganizationRegistration': () => import('./fr-FR/BuyerOrganizationRegistration'),
        'BuyerUserRegistration': () => import('./fr-FR/BuyerUserRegistration'),
        'Dashboard': () => import('./fr-FR/Dashboard'),
        'Order': () => import('./fr-FR/Order'),
        'Category': () => import('./fr-FR/Category'),
        'AccountLinksGridView': () => import('./fr-FR/AccountLinksGridView'),
        'AccountLinks': () => import('./fr-FR/AccountLinks'),
        'MyAccount': () => import('./fr-FR/MyAccount'),
        'AddressCard': () => import('./fr-FR/AddressCard'),
        'AddressForm': () => import('./fr-FR/AddressForm'),
        'ChangePassword': () => import('./fr-FR/ChangePassword'),
        'CheckoutProfile': () => import('./fr-FR/CheckoutProfile'),
        'ChildPimCategories': () => import('./fr-FR/ChildPimCategories'),
        'Confirmation': () => import('./fr-FR/Confirmation'),
        'FeaturedCard': () => import('./fr-FR/FeaturedCard'),
        'ForgotPassword': () => import('./fr-FR/ForgotPassword'),
        'PriceDisplay': () => import('./fr-FR/PriceDisplay'),
        'error-message': () => import('./fr-FR/error-message'),
        'success-message': () => import('./fr-FR/success-message'),
        'OrderBillingInfo': () => import('./fr-FR/OrderBillingInfo'),
        'OrderDetails': () => import('./fr-FR/OrderDetails'),
        'OrderDiscountSummary': () => import('./fr-FR/OrderDiscountSummary'),
        'OrderItemTable': () => import('./fr-FR/OrderItemTable'),
        'OrderLinks': () => import('./fr-FR/OrderLinks'),
        'OrderPaymentInfo': () => import('./fr-FR/OrderPaymentInfo'),
        'OrderShippingInfo': () => import('./fr-FR/OrderShippingInfo'),
        'OrderTotalSummary': () => import('./fr-FR/OrderTotalSummary'),
        'PaymentMethodContainer': () => import('./fr-FR/PaymentMethodContainer'),
        'PaymentInfoCard': () => import('./fr-FR/PaymentInfoCard'),
        'PaymentInfoList': () => import('./fr-FR/PaymentInfoList'),
        'PaymentMethodSelection': () => import('./fr-FR/PaymentMethodSelection'),
        'PersonalInformation': () => import('./fr-FR/PersonalInformation'),
        'ProductFilter': () => import('./fr-FR/ProductFilter'),
        'ProductGrid': () => import('./fr-FR/ProductGrid'),
        'PurchaseOrderNumber': () => import('./fr-FR/PurchaseOrderNumber'),
        'PurchaseOrderSelection': () => import('./fr-FR/PurchaseOrderSelection'),
        'RecurringOrderInfo': () => import('./fr-FR/RecurringOrderInfo'),
        'RegistrationLayout': () => import('./fr-FR/RegistrationLayout'),
        'RegistrationB2BLayout': () => import('./fr-FR/RegistrationB2BLayout'),
        'ResetPassword': () => import('./fr-FR/ResetPassword'),
        'SearchBar': () => import('./fr-FR/SearchBar'),
        'SessionError': () => import('./fr-FR/SessionError'),
        'SignIn': () => import('./fr-FR/SignIn'),
        'Pickup': () => import('./fr-FR/Pickup'),
        'CommerceEnvironment': () => import('./fr-FR/CommerceEnvironment'),
        'RequisitionLists': () => import('./fr-FR/RequisitionLists'),
        'RequisitionListItems': () => import('./fr-FR/RequisitionListItems'),
        'InprogressOrders': () => import('./fr-FR/InprogressOrders'),
        'InprogressItems': () => import('./fr-FR/InprogressItems'),
        'commonTable': () => import('./fr-FR/commonTable'),
        'WishList': () => import('./fr-FR/WishList'),
        'OrderMethod': () => import('./fr-FR/OrderMethod'),
        'compare': () => import('./fr-FR/compare'),
        'sellers': () => import('./fr-FR/sellers'),
        'StoreLocator': () => import('./fr-FR/StoreLocator'),
        'ProgressIndicator': () => import('./fr-FR/ProgressIndicator'),
        'Files': () => import('./fr-FR/Files'),
        'PasswordInput': () => import('./fr-FR/PasswordInput'),
        'Common': () => import('./fr-FR/Common'),
    },
    'it-IT': {
        'Routes': () => import('./it-IT/Routes'),
        'Footer': () => import('./it-IT/Footer'),
        'Header': () => import('./it-IT/Header'),
        'MegaMenu': () => import('./it-IT/MegaMenu'),
        'AllCategoriesExpandedMenu': () => import('./it-IT/AllCategoriesExpandedMenu'),
        'MiniCart': () => import('./it-IT/MiniCart'),
        'Language': () => import('./it-IT/Language'),
        'productDetail': () => import('./it-IT/productDetail'),
        'AddressBook': () => import('./it-IT/AddressBook'),
        'Cart': () => import('./it-IT/Cart'),
        'CheckoutAddress': () => import('./it-IT/CheckoutAddress'),
        'Checkout': () => import('./it-IT/Checkout'),
        'Payment': () => import('./it-IT/Payment'),
        'MultipleShipmentTable': () => import('./it-IT/MultipleShipmentTable'),
        'Shipping': () => import('./it-IT/Shipping'),
        'OrderConfirmation': () => import('./it-IT/OrderConfirmation'),
        'SignInPage': () => import('./it-IT/SignInPage'),
        'Account': () => import('./it-IT/Account'),
        'AccountSummary': () => import('./it-IT/AccountSummary'),
        'ChangePasswordSection': () => import('./it-IT/ChangePasswordSection'),
        'PersonalInformationSection': () => import('./it-IT/PersonalInformationSection'),
        'WelcomeUserSection': () => import('./it-IT/WelcomeUserSection'),
        'AdminTools': () => import('./it-IT/AdminTools'),
        'BuyerOrganizationAdminTools': () => import('./it-IT/BuyerOrganizationAdminTools'),
        'ApprovalsManagement': () => import('./it-IT/ApprovalsManagement'),
        'BuyerOrganizationRegistration': () => import('./it-IT/BuyerOrganizationRegistration'),
        'BuyerUserRegistration': () => import('./it-IT/BuyerUserRegistration'),
        'Dashboard': () => import('./it-IT/Dashboard'),
        'Order': () => import('./it-IT/Order'),
        'Category': () => import('./it-IT/Category'),
        'AccountLinksGridView': () => import('./it-IT/AccountLinksGridView'),
        'AccountLinks': () => import('./it-IT/AccountLinks'),
        'MyAccount': () => import('./it-IT/MyAccount'),
        'AddressCard': () => import('./it-IT/AddressCard'),
        'AddressForm': () => import('./it-IT/AddressForm'),
        'ChangePassword': () => import('./it-IT/ChangePassword'),
        'CheckoutProfile': () => import('./it-IT/CheckoutProfile'),
        'ChildPimCategories': () => import('./it-IT/ChildPimCategories'),
        'Confirmation': () => import('./it-IT/Confirmation'),
        'FeaturedCard': () => import('./it-IT/FeaturedCard'),
        'ForgotPassword': () => import('./it-IT/ForgotPassword'),
        'PriceDisplay': () => import('./it-IT/PriceDisplay'),
        'error-message': () => import('./it-IT/error-message'),
        'success-message': () => import('./it-IT/success-message'),
        'OrderBillingInfo': () => import('./it-IT/OrderBillingInfo'),
        'OrderDetails': () => import('./it-IT/OrderDetails'),
        'OrderDiscountSummary': () => import('./it-IT/OrderDiscountSummary'),
        'OrderItemTable': () => import('./it-IT/OrderItemTable'),
        'OrderLinks': () => import('./it-IT/OrderLinks'),
        'OrderPaymentInfo': () => import('./it-IT/OrderPaymentInfo'),
        'OrderShippingInfo': () => import('./it-IT/OrderShippingInfo'),
        'OrderTotalSummary': () => import('./it-IT/OrderTotalSummary'),
        'PaymentMethodContainer': () => import('./it-IT/PaymentMethodContainer'),
        'PaymentInfoCard': () => import('./it-IT/PaymentInfoCard'),
        'PaymentInfoList': () => import('./it-IT/PaymentInfoList'),
        'PaymentMethodSelection': () => import('./it-IT/PaymentMethodSelection'),
        'PersonalInformation': () => import('./it-IT/PersonalInformation'),
        'ProductFilter': () => import('./it-IT/ProductFilter'),
        'ProductGrid': () => import('./it-IT/ProductGrid'),
        'PurchaseOrderNumber': () => import('./it-IT/PurchaseOrderNumber'),
        'PurchaseOrderSelection': () => import('./it-IT/PurchaseOrderSelection'),
        'RecurringOrderInfo': () => import('./it-IT/RecurringOrderInfo'),
        'RegistrationLayout': () => import('./it-IT/RegistrationLayout'),
        'RegistrationB2BLayout': () => import('./it-IT/RegistrationB2BLayout'),
        'ResetPassword': () => import('./it-IT/ResetPassword'),
        'SearchBar': () => import('./it-IT/SearchBar'),
        'SessionError': () => import('./it-IT/SessionError'),
        'SignIn': () => import('./it-IT/SignIn'),
        'Pickup': () => import('./it-IT/Pickup'),
        'CommerceEnvironment': () => import('./it-IT/CommerceEnvironment'),
        'RequisitionLists': () => import('./it-IT/RequisitionLists'),
        'RequisitionListItems': () => import('./it-IT/RequisitionListItems'),
        'InprogressOrders': () => import('./it-IT/InprogressOrders'),
        'InprogressItems': () => import('./it-IT/InprogressItems'),
        'commonTable': () => import('./it-IT/commonTable'),
        'WishList': () => import('./it-IT/WishList'),
        'OrderMethod': () => import('./it-IT/OrderMethod'),
        'compare': () => import('./it-IT/compare'),
        'sellers': () => import('./it-IT/sellers'),
        'StoreLocator': () => import('./it-IT/StoreLocator'),
        'ProgressIndicator': () => import('./it-IT/ProgressIndicator'),
        'Files': () => import('./it-IT/Files'),
        'PasswordInput': () => import('./it-IT/PasswordInput'),
        'Common': () => import('./it-IT/Common'),
    },
    'ja-JP': {
        'Routes': () => import('./ja-JP/Routes'),
        'Footer': () => import('./ja-JP/Footer'),
        'Header': () => import('./ja-JP/Header'),
        'MegaMenu': () => import('./ja-JP/MegaMenu'),
        'AllCategoriesExpandedMenu': () => import('./ja-JP/AllCategoriesExpandedMenu'),
        'MiniCart': () => import('./ja-JP/MiniCart'),
        'Language': () => import('./ja-JP/Language'),
        'productDetail': () => import('./ja-JP/productDetail'),
        'AddressBook': () => import('./ja-JP/AddressBook'),
        'Cart': () => import('./ja-JP/Cart'),
        'CheckoutAddress': () => import('./ja-JP/CheckoutAddress'),
        'Checkout': () => import('./ja-JP/Checkout'),
        'Payment': () => import('./ja-JP/Payment'),
        'MultipleShipmentTable': () => import('./ja-JP/MultipleShipmentTable'),
        'Shipping': () => import('./ja-JP/Shipping'),
        'OrderConfirmation': () => import('./ja-JP/OrderConfirmation'),
        'SignInPage': () => import('./ja-JP/SignInPage'),
        'Account': () => import('./ja-JP/Account'),
        'AccountSummary': () => import('./ja-JP/AccountSummary'),
        'ChangePasswordSection': () => import('./ja-JP/ChangePasswordSection'),
        'PersonalInformationSection': () => import('./ja-JP/PersonalInformationSection'),
        'WelcomeUserSection': () => import('./ja-JP/WelcomeUserSection'),
        'AdminTools': () => import('./ja-JP/AdminTools'),
        'BuyerOrganizationAdminTools': () => import('./ja-JP/BuyerOrganizationAdminTools'),
        'ApprovalsManagement': () => import('./ja-JP/ApprovalsManagement'),
        'BuyerOrganizationRegistration': () => import('./ja-JP/BuyerOrganizationRegistration'),
        'BuyerUserRegistration': () => import('./ja-JP/BuyerUserRegistration'),
        'Dashboard': () => import('./ja-JP/Dashboard'),
        'Order': () => import('./ja-JP/Order'),
        'Category': () => import('./ja-JP/Category'),
        'AccountLinksGridView': () => import('./ja-JP/AccountLinksGridView'),
        'AccountLinks': () => import('./ja-JP/AccountLinks'),
        'MyAccount': () => import('./ja-JP/MyAccount'),
        'AddressCard': () => import('./ja-JP/AddressCard'),
        'AddressForm': () => import('./ja-JP/AddressForm'),
        'ChangePassword': () => import('./ja-JP/ChangePassword'),
        'CheckoutProfile': () => import('./ja-JP/CheckoutProfile'),
        'ChildPimCategories': () => import('./ja-JP/ChildPimCategories'),
        'Confirmation': () => import('./ja-JP/Confirmation'),
        'FeaturedCard': () => import('./ja-JP/FeaturedCard'),
        'ForgotPassword': () => import('./ja-JP/ForgotPassword'),
        'PriceDisplay': () => import('./ja-JP/PriceDisplay'),
        'error-message': () => import('./ja-JP/error-message'),
        'success-message': () => import('./ja-JP/success-message'),
        'OrderBillingInfo': () => import('./ja-JP/OrderBillingInfo'),
        'OrderDetails': () => import('./ja-JP/OrderDetails'),
        'OrderDiscountSummary': () => import('./ja-JP/OrderDiscountSummary'),
        'OrderItemTable': () => import('./ja-JP/OrderItemTable'),
        'OrderLinks': () => import('./ja-JP/OrderLinks'),
        'OrderPaymentInfo': () => import('./ja-JP/OrderPaymentInfo'),
        'OrderShippingInfo': () => import('./ja-JP/OrderShippingInfo'),
        'OrderTotalSummary': () => import('./ja-JP/OrderTotalSummary'),
        'PaymentMethodContainer': () => import('./ja-JP/PaymentMethodContainer'),
        'PaymentInfoCard': () => import('./ja-JP/PaymentInfoCard'),
        'PaymentInfoList': () => import('./ja-JP/PaymentInfoList'),
        'PaymentMethodSelection': () => import('./ja-JP/PaymentMethodSelection'),
        'PersonalInformation': () => import('./ja-JP/PersonalInformation'),
        'ProductFilter': () => import('./ja-JP/ProductFilter'),
        'ProductGrid': () => import('./ja-JP/ProductGrid'),
        'PurchaseOrderNumber': () => import('./ja-JP/PurchaseOrderNumber'),
        'PurchaseOrderSelection': () => import('./ja-JP/PurchaseOrderSelection'),
        'RecurringOrderInfo': () => import('./ja-JP/RecurringOrderInfo'),
        'RegistrationLayout': () => import('./ja-JP/RegistrationLayout'),
        'RegistrationB2BLayout': () => import('./ja-JP/RegistrationB2BLayout'),
        'ResetPassword': () => import('./ja-JP/ResetPassword'),
        'SearchBar': () => import('./ja-JP/SearchBar'),
        'SessionError': () => import('./ja-JP/SessionError'),
        'SignIn': () => import('./ja-JP/SignIn'),
        'Pickup': () => import('./ja-JP/Pickup'),
        'CommerceEnvironment': () => import('./ja-JP/CommerceEnvironment'),
        'RequisitionLists': () => import('./ja-JP/RequisitionLists'),
        'RequisitionListItems': () => import('./ja-JP/RequisitionListItems'),
        'InprogressOrders': () => import('./ja-JP/InprogressOrders'),
        'InprogressItems': () => import('./ja-JP/InprogressItems'),
        'commonTable': () => import('./ja-JP/commonTable'),
        'WishList': () => import('./ja-JP/WishList'),
        'OrderMethod': () => import('./ja-JP/OrderMethod'),
        'compare': () => import('./ja-JP/compare'),
        'sellers': () => import('./ja-JP/sellers'),
        'StoreLocator': () => import('./ja-JP/StoreLocator'),
        'ProgressIndicator': () => import('./ja-JP/ProgressIndicator'),
        'Files': () => import('./ja-JP/Files'),
        'PasswordInput': () => import('./ja-JP/PasswordInput'),
        'Common': () => import('./ja-JP/Common'),
    },
    'ko-KR': {
        'Routes': () => import('./ko-KR/Routes'),
        'Footer': () => import('./ko-KR/Footer'),
        'Header': () => import('./ko-KR/Header'),
        'MegaMenu': () => import('./ko-KR/MegaMenu'),
        'AllCategoriesExpandedMenu': () => import('./ko-KR/AllCategoriesExpandedMenu'),
        'MiniCart': () => import('./ko-KR/MiniCart'),
        'Language': () => import('./ko-KR/Language'),
        'productDetail': () => import('./ko-KR/productDetail'),
        'AddressBook': () => import('./ko-KR/AddressBook'),
        'Cart': () => import('./ko-KR/Cart'),
        'CheckoutAddress': () => import('./ko-KR/CheckoutAddress'),
        'Checkout': () => import('./ko-KR/Checkout'),
        'Payment': () => import('./ko-KR/Payment'),
        'MultipleShipmentTable': () => import('./ko-KR/MultipleShipmentTable'),
        'Shipping': () => import('./ko-KR/Shipping'),
        'OrderConfirmation': () => import('./ko-KR/OrderConfirmation'),
        'SignInPage': () => import('./ko-KR/SignInPage'),
        'Account': () => import('./ko-KR/Account'),
        'AccountSummary': () => import('./ko-KR/AccountSummary'),
        'ChangePasswordSection': () => import('./ko-KR/ChangePasswordSection'),
        'PersonalInformationSection': () => import('./ko-KR/PersonalInformationSection'),
        'WelcomeUserSection': () => import('./ko-KR/WelcomeUserSection'),
        'AdminTools': () => import('./ko-KR/AdminTools'),
        'BuyerOrganizationAdminTools': () => import('./ko-KR/BuyerOrganizationAdminTools'),
        'ApprovalsManagement': () => import('./ko-KR/ApprovalsManagement'),
        'BuyerOrganizationRegistration': () => import('./ko-KR/BuyerOrganizationRegistration'),
        'BuyerUserRegistration': () => import('./ko-KR/BuyerUserRegistration'),
        'Dashboard': () => import('./ko-KR/Dashboard'),
        'Order': () => import('./ko-KR/Order'),
        'Category': () => import('./ko-KR/Category'),
        'AccountLinksGridView': () => import('./ko-KR/AccountLinksGridView'),
        'AccountLinks': () => import('./ko-KR/AccountLinks'),
        'MyAccount': () => import('./ko-KR/MyAccount'),
        'AddressCard': () => import('./ko-KR/AddressCard'),
        'AddressForm': () => import('./ko-KR/AddressForm'),
        'ChangePassword': () => import('./ko-KR/ChangePassword'),
        'CheckoutProfile': () => import('./ko-KR/CheckoutProfile'),
        'ChildPimCategories': () => import('./ko-KR/ChildPimCategories'),
        'Confirmation': () => import('./ko-KR/Confirmation'),
        'FeaturedCard': () => import('./ko-KR/FeaturedCard'),
        'ForgotPassword': () => import('./ko-KR/ForgotPassword'),
        'PriceDisplay': () => import('./ko-KR/PriceDisplay'),
        'error-message': () => import('./ko-KR/error-message'),
        'success-message': () => import('./ko-KR/success-message'),
        'OrderBillingInfo': () => import('./ko-KR/OrderBillingInfo'),
        'OrderDetails': () => import('./ko-KR/OrderDetails'),
        'OrderDiscountSummary': () => import('./ko-KR/OrderDiscountSummary'),
        'OrderItemTable': () => import('./ko-KR/OrderItemTable'),
        'OrderLinks': () => import('./ko-KR/OrderLinks'),
        'OrderPaymentInfo': () => import('./ko-KR/OrderPaymentInfo'),
        'OrderShippingInfo': () => import('./ko-KR/OrderShippingInfo'),
        'OrderTotalSummary': () => import('./ko-KR/OrderTotalSummary'),
        'PaymentMethodContainer': () => import('./ko-KR/PaymentMethodContainer'),
        'PaymentInfoCard': () => import('./ko-KR/PaymentInfoCard'),
        'PaymentInfoList': () => import('./ko-KR/PaymentInfoList'),
        'PaymentMethodSelection': () => import('./ko-KR/PaymentMethodSelection'),
        'PersonalInformation': () => import('./ko-KR/PersonalInformation'),
        'ProductFilter': () => import('./ko-KR/ProductFilter'),
        'ProductGrid': () => import('./ko-KR/ProductGrid'),
        'PurchaseOrderNumber': () => import('./ko-KR/PurchaseOrderNumber'),
        'PurchaseOrderSelection': () => import('./ko-KR/PurchaseOrderSelection'),
        'RecurringOrderInfo': () => import('./ko-KR/RecurringOrderInfo'),
        'RegistrationLayout': () => import('./ko-KR/RegistrationLayout'),
        'RegistrationB2BLayout': () => import('./ko-KR/RegistrationB2BLayout'),
        'ResetPassword': () => import('./ko-KR/ResetPassword'),
        'SearchBar': () => import('./ko-KR/SearchBar'),
        'SessionError': () => import('./ko-KR/SessionError'),
        'SignIn': () => import('./ko-KR/SignIn'),
        'Pickup': () => import('./ko-KR/Pickup'),
        'CommerceEnvironment': () => import('./ko-KR/CommerceEnvironment'),
        'RequisitionLists': () => import('./ko-KR/RequisitionLists'),
        'RequisitionListItems': () => import('./ko-KR/RequisitionListItems'),
        'InprogressOrders': () => import('./ko-KR/InprogressOrders'),
        'InprogressItems': () => import('./ko-KR/InprogressItems'),
        'commonTable': () => import('./ko-KR/commonTable'),
        'WishList': () => import('./ko-KR/WishList'),
        'OrderMethod': () => import('./ko-KR/OrderMethod'),
        'compare': () => import('./ko-KR/compare'),
        'sellers': () => import('./ko-KR/sellers'),
        'StoreLocator': () => import('./ko-KR/StoreLocator'),
        'ProgressIndicator': () => import('./ko-KR/ProgressIndicator'),
        'Files': () => import('./ko-KR/Files'),
        'PasswordInput': () => import('./ko-KR/PasswordInput'),
        'Common': () => import('./ko-KR/Common'),
    },
    'pl-PL': {
        'Routes': () => import('./pl-PL/Routes'),
        'Footer': () => import('./pl-PL/Footer'),
        'Header': () => import('./pl-PL/Header'),
        'MegaMenu': () => import('./pl-PL/MegaMenu'),
        'AllCategoriesExpandedMenu': () => import('./pl-PL/AllCategoriesExpandedMenu'),
        'MiniCart': () => import('./pl-PL/MiniCart'),
        'Language': () => import('./pl-PL/Language'),
        'productDetail': () => import('./pl-PL/productDetail'),
        'AddressBook': () => import('./pl-PL/AddressBook'),
        'Cart': () => import('./pl-PL/Cart'),
        'CheckoutAddress': () => import('./pl-PL/CheckoutAddress'),
        'Checkout': () => import('./pl-PL/Checkout'),
        'Payment': () => import('./pl-PL/Payment'),
        'MultipleShipmentTable': () => import('./pl-PL/MultipleShipmentTable'),
        'Shipping': () => import('./pl-PL/Shipping'),
        'OrderConfirmation': () => import('./pl-PL/OrderConfirmation'),
        'SignInPage': () => import('./pl-PL/SignInPage'),
        'Account': () => import('./pl-PL/Account'),
        'AccountSummary': () => import('./pl-PL/AccountSummary'),
        'ChangePasswordSection': () => import('./pl-PL/ChangePasswordSection'),
        'PersonalInformationSection': () => import('./pl-PL/PersonalInformationSection'),
        'WelcomeUserSection': () => import('./pl-PL/WelcomeUserSection'),
        'AdminTools': () => import('./pl-PL/AdminTools'),
        'BuyerOrganizationRegistration': () => import('./pl-PL/BuyerOrganizationRegistration'),
        'BuyerUserRegistration': () => import('./pl-PL/BuyerUserRegistration'),
        'Dashboard': () => import('./pl-PL/Dashboard'),
        'Order': () => import('./pl-PL/Order'),
        'Category': () => import('./pl-PL/Category'),
        'AccountLinksGridView': () => import('./pl-PL/AccountLinksGridView'),
        'AccountLinks': () => import('./pl-PL/AccountLinks'),
        'MyAccount': () => import('./pl-PL/MyAccount'),
        'AddressCard': () => import('./pl-PL/AddressCard'),
        'AddressForm': () => import('./pl-PL/AddressForm'),
        'ChangePassword': () => import('./pl-PL/ChangePassword'),
        'CheckoutProfile': () => import('./pl-PL/CheckoutProfile'),
        'ChildPimCategories': () => import('./pl-PL/ChildPimCategories'),
        'Confirmation': () => import('./pl-PL/Confirmation'),
        'FeaturedCard': () => import('./pl-PL/FeaturedCard'),
        'ForgotPassword': () => import('./pl-PL/ForgotPassword'),
        'PriceDisplay': () => import('./pl-PL/PriceDisplay'),
        'error-message': () => import('./pl-PL/error-message'),
        'success-message': () => import('./pl-PL/success-message'),
        'OrderBillingInfo': () => import('./pl-PL/OrderBillingInfo'),
        'OrderDetails': () => import('./pl-PL/OrderDetails'),
        'OrderDiscountSummary': () => import('./pl-PL/OrderDiscountSummary'),
        'OrderItemTable': () => import('./pl-PL/OrderItemTable'),
        'OrderLinks': () => import('./pl-PL/OrderLinks'),
        'OrderPaymentInfo': () => import('./pl-PL/OrderPaymentInfo'),
        'OrderShippingInfo': () => import('./pl-PL/OrderShippingInfo'),
        'OrderTotalSummary': () => import('./pl-PL/OrderTotalSummary'),
        'PaymentMethodContainer': () => import('./pl-PL/PaymentMethodContainer'),
        'PaymentInfoCard': () => import('./pl-PL/PaymentInfoCard'),
        'PaymentInfoList': () => import('./pl-PL/PaymentInfoList'),
        'PaymentMethodSelection': () => import('./pl-PL/PaymentMethodSelection'),
        'PersonalInformation': () => import('./pl-PL/PersonalInformation'),
        'ProductFilter': () => import('./pl-PL/ProductFilter'),
        'ProductGrid': () => import('./pl-PL/ProductGrid'),
        'PurchaseOrderNumber': () => import('./pl-PL/PurchaseOrderNumber'),
        'PurchaseOrderSelection': () => import('./pl-PL/PurchaseOrderSelection'),
        'RecurringOrderInfo': () => import('./pl-PL/RecurringOrderInfo'),
        'RegistrationLayout': () => import('./pl-PL/RegistrationLayout'),
        'RegistrationB2BLayout': () => import('./pl-PL/RegistrationB2BLayout'),
        'ResetPassword': () => import('./pl-PL/ResetPassword'),
        'SearchBar': () => import('./pl-PL/SearchBar'),
        'SessionError': () => import('./pl-PL/SessionError'),
        'SignIn': () => import('./pl-PL/SignIn'),
        'Pickup': () => import('./pl-PL/Pickup'),
        'CommerceEnvironment': () => import('./pl-PL/CommerceEnvironment'),
        'RequisitionLists': () => import('./pl-PL/RequisitionLists'),
        'RequisitionListItems': () => import('./pl-PL/RequisitionListItems'),
        'InprogressOrders': () => import('./pl-PL/InprogressOrders'),
        'InprogressItems': () => import('./pl-PL/InprogressItems'),
        'commonTable': () => import('./pl-PL/commonTable'),
        'WishList': () => import('./pl-PL/WishList'),
        'OrderMethod': () => import('./pl-PL/OrderMethod'),
        'compare': () => import('./pl-PL/compare'),
        'sellers': () => import('./pl-PL/sellers'),
        'StoreLocator': () => import('./pl-PL/StoreLocator'),
        'ProgressIndicator': () => import('./pl-PL/ProgressIndicator'),
        'Files': () => import('./pl-PL/Files'),
        'PasswordInput': () => import('./pl-PL/PasswordInput'),
        'Common': () => import('./pl-PL/Common'),
    },
    'pt-BR': {
        'Routes': () => import('./pt-BR/Routes'),
        'Footer': () => import('./pt-BR/Footer'),
        'Header': () => import('./pt-BR/Header'),
        'MegaMenu': () => import('./pt-BR/MegaMenu'),
        'AllCategoriesExpandedMenu': () => import('./pt-BR/AllCategoriesExpandedMenu'),
        'MiniCart': () => import('./pt-BR/MiniCart'),
        'Language': () => import('./pt-BR/Language'),
        'productDetail': () => import('./pt-BR/productDetail'),
        'AddressBook': () => import('./pt-BR/AddressBook'),
        'Cart': () => import('./pt-BR/Cart'),
        'CheckoutAddress': () => import('./pt-BR/CheckoutAddress'),
        'Checkout': () => import('./pt-BR/Checkout'),
        'Payment': () => import('./pt-BR/Payment'),
        'MultipleShipmentTable': () => import('./pt-BR/MultipleShipmentTable'),
        'Shipping': () => import('./pt-BR/Shipping'),
        'OrderConfirmation': () => import('./pt-BR/OrderConfirmation'),
        'SignInPage': () => import('./pt-BR/SignInPage'),
        'Account': () => import('./pt-BR/Account'),
        'AccountSummary': () => import('./pt-BR/AccountSummary'),
        'ChangePasswordSection': () => import('./pt-BR/ChangePasswordSection'),
        'PersonalInformationSection': () => import('./pt-BR/PersonalInformationSection'),
        'WelcomeUserSection': () => import('./pt-BR/WelcomeUserSection'),
        'AdminTools': () => import('./pt-BR/AdminTools'),
        'BuyerOrganizationRegistration': () => import('./pt-BR/BuyerOrganizationRegistration'),
        'BuyerUserRegistration': () => import('./pt-BR/BuyerUserRegistration'),
        'Dashboard': () => import('./pt-BR/Dashboard'),
        'Order': () => import('./pt-BR/Order'),
        'Category': () => import('./pt-BR/Category'),
        'AccountLinksGridView': () => import('./pt-BR/AccountLinksGridView'),
        'AccountLinks': () => import('./pt-BR/AccountLinks'),
        'MyAccount': () => import('./pt-BR/MyAccount'),
        'AddressCard': () => import('./pt-BR/AddressCard'),
        'AddressForm': () => import('./pt-BR/AddressForm'),
        'ChangePassword': () => import('./pt-BR/ChangePassword'),
        'CheckoutProfile': () => import('./pt-BR/CheckoutProfile'),
        'ChildPimCategories': () => import('./pt-BR/ChildPimCategories'),
        'Confirmation': () => import('./pt-BR/Confirmation'),
        'FeaturedCard': () => import('./pt-BR/FeaturedCard'),
        'ForgotPassword': () => import('./pt-BR/ForgotPassword'),
        'PriceDisplay': () => import('./pt-BR/PriceDisplay'),
        'error-message': () => import('./pt-BR/error-message'),
        'success-message': () => import('./pt-BR/success-message'),
        'OrderBillingInfo': () => import('./pt-BR/OrderBillingInfo'),
        'OrderDetails': () => import('./pt-BR/OrderDetails'),
        'OrderDiscountSummary': () => import('./pt-BR/OrderDiscountSummary'),
        'OrderItemTable': () => import('./pt-BR/OrderItemTable'),
        'OrderLinks': () => import('./pt-BR/OrderLinks'),
        'OrderPaymentInfo': () => import('./pt-BR/OrderPaymentInfo'),
        'OrderShippingInfo': () => import('./pt-BR/OrderShippingInfo'),
        'OrderTotalSummary': () => import('./pt-BR/OrderTotalSummary'),
        'PaymentMethodContainer': () => import('./pt-BR/PaymentMethodContainer'),
        'PaymentInfoCard': () => import('./pt-BR/PaymentInfoCard'),
        'PaymentInfoList': () => import('./pt-BR/PaymentInfoList'),
        'PaymentMethodSelection': () => import('./pt-BR/PaymentMethodSelection'),
        'PersonalInformation': () => import('./pt-BR/PersonalInformation'),
        'ProductFilter': () => import('./pt-BR/ProductFilter'),
        'ProductGrid': () => import('./pt-BR/ProductGrid'),
        'PurchaseOrderNumber': () => import('./pt-BR/PurchaseOrderNumber'),
        'PurchaseOrderSelection': () => import('./pt-BR/PurchaseOrderSelection'),
        'RecurringOrderInfo': () => import('./pt-BR/RecurringOrderInfo'),
        'RegistrationLayout': () => import('./pt-BR/RegistrationLayout'),
        'RegistrationB2BLayout': () => import('./pt-BR/RegistrationB2BLayout'),
        'ResetPassword': () => import('./pt-BR/ResetPassword'),
        'SearchBar': () => import('./pt-BR/SearchBar'),
        'SessionError': () => import('./pt-BR/SessionError'),
        'SignIn': () => import('./pt-BR/SignIn'),
        'Pickup': () => import('./pt-BR/Pickup'),
        'CommerceEnvironment': () => import('./pt-BR/CommerceEnvironment'),
        'RequisitionLists': () => import('./pt-BR/RequisitionLists'),
        'RequisitionListItems': () => import('./pt-BR/RequisitionListItems'),
        'InprogressOrders': () => import('./pt-BR/InprogressOrders'),
        'InprogressItems': () => import('./pt-BR/InprogressItems'),
        'commonTable': () => import('./pt-BR/commonTable'),
        'WishList': () => import('./pt-BR/WishList'),
        'OrderMethod': () => import('./pt-BR/OrderMethod'),
        'compare': () => import('./pt-BR/compare'),
        'sellers': () => import('./pt-BR/sellers'),
        'StoreLocator': () => import('./pt-BR/StoreLocator'),
        'ProgressIndicator': () => import('./pt-BR/ProgressIndicator'),
        'Files': () => import('./pt-BR/Files'),
        'PasswordInput': () => import('./pt-BR/PasswordInput'),
        'Common': () => import('./pt-BR/Common'),
    },
    'pt-PT': {
        'Routes': () => import('./pt-PT/Routes'),
        'Footer': () => import('./pt-PT/Footer'),
        'Header': () => import('./pt-PT/Header'),
        'MegaMenu': () => import('./pt-PT/MegaMenu'),
        'AllCategoriesExpandedMenu': () => import('./pt-PT/AllCategoriesExpandedMenu'),
        'MiniCart': () => import('./pt-PT/MiniCart'),
        'Language': () => import('./pt-PT/Language'),
        'productDetail': () => import('./pt-PT/productDetail'),
        'AddressBook': () => import('./pt-PT/AddressBook'),
        'Cart': () => import('./pt-PT/Cart'),
        'CheckoutAddress': () => import('./pt-PT/CheckoutAddress'),
        'Checkout': () => import('./pt-PT/Checkout'),
        'Payment': () => import('./pt-PT/Payment'),
        'MultipleShipmentTable': () => import('./pt-PT/MultipleShipmentTable'),
        'Shipping': () => import('./pt-PT/Shipping'),
        'OrderConfirmation': () => import('./pt-PT/OrderConfirmation'),
        'SignInPage': () => import('./pt-PT/SignInPage'),
        'Account': () => import('./pt-PT/Account'),
        'AccountSummary': () => import('./pt-PT/AccountSummary'),
        'ChangePasswordSection': () => import('./pt-PT/ChangePasswordSection'),
        'PersonalInformationSection': () => import('./pt-PT/PersonalInformationSection'),
        'WelcomeUserSection': () => import('./pt-PT/WelcomeUserSection'),
        'AdminTools': () => import('./pt-PT/AdminTools'),
        'BuyerOrganizationAdminTools': () => import('./pt-PT/BuyerOrganizationAdminTools'),
        'ApprovalsManagement': () => import('./pt-PT/ApprovalsManagement'),
        'BuyerOrganizationRegistration': () => import('./pt-PT/BuyerOrganizationRegistration'),
        'BuyerUserRegistration': () => import('./pt-PT/BuyerUserRegistration'),
        'Dashboard': () => import('./pt-PT/Dashboard'),
        'Order': () => import('./pt-PT/Order'),
        'Category': () => import('./pt-PT/Category'),
        'AccountLinksGridView': () => import('./pt-PT/AccountLinksGridView'),
        'AccountLinks': () => import('./pt-PT/AccountLinks'),
        'MyAccount': () => import('./pt-PT/MyAccount'),
        'AddressCard': () => import('./pt-PT/AddressCard'),
        'AddressForm': () => import('./pt-PT/AddressForm'),
        'ChangePassword': () => import('./pt-PT/ChangePassword'),
        'CheckoutProfile': () => import('./pt-PT/CheckoutProfile'),
        'ChildPimCategories': () => import('./pt-PT/ChildPimCategories'),
        'Confirmation': () => import('./pt-PT/Confirmation'),
        'FeaturedCard': () => import('./pt-PT/FeaturedCard'),
        'ForgotPassword': () => import('./pt-PT/ForgotPassword'),
        'PriceDisplay': () => import('./pt-PT/PriceDisplay'),
        'error-message': () => import('./pt-PT/error-message'),
        'success-message': () => import('./pt-PT/success-message'),
        'OrderBillingInfo': () => import('./pt-PT/OrderBillingInfo'),
        'OrderDetails': () => import('./pt-PT/OrderDetails'),
        'OrderDiscountSummary': () => import('./pt-PT/OrderDiscountSummary'),
        'OrderItemTable': () => import('./pt-PT/OrderItemTable'),
        'OrderLinks': () => import('./pt-PT/OrderLinks'),
        'OrderPaymentInfo': () => import('./pt-PT/OrderPaymentInfo'),
        'OrderShippingInfo': () => import('./pt-PT/OrderShippingInfo'),
        'OrderTotalSummary': () => import('./pt-PT/OrderTotalSummary'),
        'PaymentMethodContainer': () => import('./pt-PT/PaymentMethodContainer'),
        'PaymentInfoCard': () => import('./pt-PT/PaymentInfoCard'),
        'PaymentInfoList': () => import('./pt-PT/PaymentInfoList'),
        'PaymentMethodSelection': () => import('./pt-PT/PaymentMethodSelection'),
        'PersonalInformation': () => import('./pt-PT/PersonalInformation'),
        'ProductFilter': () => import('./pt-PT/ProductFilter'),
        'ProductGrid': () => import('./pt-PT/ProductGrid'),
        'PurchaseOrderNumber': () => import('./pt-PT/PurchaseOrderNumber'),
        'PurchaseOrderSelection': () => import('./pt-PT/PurchaseOrderSelection'),
        'RecurringOrderInfo': () => import('./pt-PT/RecurringOrderInfo'),
        'RegistrationLayout': () => import('./pt-PT/RegistrationLayout'),
        'RegistrationB2BLayout': () => import('./pt-PT/RegistrationB2BLayout'),
        'ResetPassword': () => import('./pt-PT/ResetPassword'),
        'SearchBar': () => import('./pt-PT/SearchBar'),
        'SessionError': () => import('./pt-PT/SessionError'),
        'SignIn': () => import('./pt-PT/SignIn'),
        'Pickup': () => import('./pt-PT/Pickup'),
        'CommerceEnvironment': () => import('./pt-PT/CommerceEnvironment'),
        'RequisitionLists': () => import('./pt-PT/RequisitionLists'),
        'RequisitionListItems': () => import('./pt-PT/RequisitionListItems'),
        'InprogressOrders': () => import('./pt-PT/InprogressOrders'),
        'InprogressItems': () => import('./pt-PT/InprogressItems'),
        'commonTable': () => import('./pt-PT/commonTable'),
        'WishList': () => import('./pt-PT/WishList'),
        'OrderMethod': () => import('./pt-PT/OrderMethod'),
        'compare': () => import('./pt-PT/compare'),
        'sellers': () => import('./pt-PT/sellers'),
        'StoreLocator': () => import('./pt-PT/StoreLocator'),
        'ProgressIndicator': () => import('./pt-PT/ProgressIndicator'),
        'Files': () => import('./pt-PT/Files'),
        'PasswordInput': () => import('./pt-PT/PasswordInput'),
        'Common': () => import('./pt-PT/Common'),
    },
    'ro-RO': {
        'Routes': () => import('./ro-RO/Routes'),
        'Footer': () => import('./ro-RO/Footer'),
        'Header': () => import('./ro-RO/Header'),
        'MegaMenu': () => import('./ro-RO/MegaMenu'),
        'AllCategoriesExpandedMenu': () => import('./ro-RO/AllCategoriesExpandedMenu'),
        'MiniCart': () => import('./ro-RO/MiniCart'),
        'Language': () => import('./ro-RO/Language'),
        'productDetail': () => import('./ro-RO/productDetail'),
        'AddressBook': () => import('./ro-RO/AddressBook'),
        'Cart': () => import('./ro-RO/Cart'),
        'CheckoutAddress': () => import('./ro-RO/CheckoutAddress'),
        'Checkout': () => import('./ro-RO/Checkout'),
        'Payment': () => import('./ro-RO/Payment'),
        'MultipleShipmentTable': () => import('./ro-RO/MultipleShipmentTable'),
        'Shipping': () => import('./ro-RO/Shipping'),
        'OrderConfirmation': () => import('./ro-RO/OrderConfirmation'),
        'SignInPage': () => import('./ro-RO/SignInPage'),
        'Account': () => import('./ro-RO/Account'),
        'AccountSummary': () => import('./ro-RO/AccountSummary'),
        'ChangePasswordSection': () => import('./ro-RO/ChangePasswordSection'),
        'PersonalInformationSection': () => import('./ro-RO/PersonalInformationSection'),
        'WelcomeUserSection': () => import('./ro-RO/WelcomeUserSection'),
        'AdminTools': () => import('./ro-RO/AdminTools'),
        'BuyerOrganizationAdminTools': () => import('./ro-RO/BuyerOrganizationAdminTools'),
        'ApprovalsManagement': () => import('./ro-RO/ApprovalsManagement'),
        'BuyerOrganizationRegistration': () => import('./ro-RO/BuyerOrganizationRegistration'),
        'BuyerUserRegistration': () => import('./ro-RO/BuyerUserRegistration'),
        'Dashboard': () => import('./ro-RO/Dashboard'),
        'Order': () => import('./ro-RO/Order'),
        'Category': () => import('./ro-RO/Category'),
        'AccountLinksGridView': () => import('./ro-RO/AccountLinksGridView'),
        'AccountLinks': () => import('./ro-RO/AccountLinks'),
        'MyAccount': () => import('./ro-RO/MyAccount'),
        'AddressCard': () => import('./ro-RO/AddressCard'),
        'AddressForm': () => import('./ro-RO/AddressForm'),
        'ChangePassword': () => import('./ro-RO/ChangePassword'),
        'CheckoutProfile': () => import('./ro-RO/CheckoutProfile'),
        'ChildPimCategories': () => import('./ro-RO/ChildPimCategories'),
        'Confirmation': () => import('./ro-RO/Confirmation'),
        'FeaturedCard': () => import('./ro-RO/FeaturedCard'),
        'ForgotPassword': () => import('./ro-RO/ForgotPassword'),
        'PriceDisplay': () => import('./ro-RO/PriceDisplay'),
        'error-message': () => import('./ro-RO/error-message'),
        'success-message': () => import('./ro-RO/success-message'),
        'OrderBillingInfo': () => import('./ro-RO/OrderBillingInfo'),
        'OrderDetails': () => import('./ro-RO/OrderDetails'),
        'OrderDiscountSummary': () => import('./ro-RO/OrderDiscountSummary'),
        'OrderItemTable': () => import('./ro-RO/OrderItemTable'),
        'OrderLinks': () => import('./ro-RO/OrderLinks'),
        'OrderPaymentInfo': () => import('./ro-RO/OrderPaymentInfo'),
        'OrderShippingInfo': () => import('./ro-RO/OrderShippingInfo'),
        'OrderTotalSummary': () => import('./ro-RO/OrderTotalSummary'),
        'PaymentMethodContainer': () => import('./ro-RO/PaymentMethodContainer'),
        'PaymentInfoCard': () => import('./ro-RO/PaymentInfoCard'),
        'PaymentInfoList': () => import('./ro-RO/PaymentInfoList'),
        'PaymentMethodSelection': () => import('./ro-RO/PaymentMethodSelection'),
        'PersonalInformation': () => import('./ro-RO/PersonalInformation'),
        'ProductFilter': () => import('./ro-RO/ProductFilter'),
        'ProductGrid': () => import('./ro-RO/ProductGrid'),
        'PurchaseOrderNumber': () => import('./ro-RO/PurchaseOrderNumber'),
        'PurchaseOrderSelection': () => import('./ro-RO/PurchaseOrderSelection'),
        'RecurringOrderInfo': () => import('./ro-RO/RecurringOrderInfo'),
        'RegistrationLayout': () => import('./ro-RO/RegistrationLayout'),
        'RegistrationB2BLayout': () => import('./ro-RO/RegistrationB2BLayout'),
        'ResetPassword': () => import('./ro-RO/ResetPassword'),
        'SearchBar': () => import('./ro-RO/SearchBar'),
        'SessionError': () => import('./ro-RO/SessionError'),
        'SignIn': () => import('./ro-RO/SignIn'),
        'Pickup': () => import('./ro-RO/Pickup'),
        'CommerceEnvironment': () => import('./ro-RO/CommerceEnvironment'),
        'RequisitionLists': () => import('./ro-RO/RequisitionLists'),
        'RequisitionListItems': () => import('./ro-RO/RequisitionListItems'),
        'InprogressOrders': () => import('./ro-RO/InprogressOrders'),
        'InprogressItems': () => import('./ro-RO/InprogressItems'),
        'commonTable': () => import('./ro-RO/commonTable'),
        'WishList': () => import('./ro-RO/WishList'),
        'OrderMethod': () => import('./ro-RO/OrderMethod'),
        'compare': () => import('./ro-RO/compare'),
        'sellers': () => import('./ro-RO/sellers'),
        'StoreLocator': () => import('./ro-RO/StoreLocator'),
        'ProgressIndicator': () => import('./ro-RO/ProgressIndicator'),
        'Files': () => import('./ro-RO/Files'),
        'PasswordInput': () => import('./ro-RO/PasswordInput'),
        'Common': () => import('./ro-RO/Common'),
    },
    'ru-RU': {
        'Routes': () => import('./ru-RU/Routes'),
        'Footer': () => import('./ru-RU/Footer'),
        'Header': () => import('./ru-RU/Header'),
        'MegaMenu': () => import('./ru-RU/MegaMenu'),
        'AllCategoriesExpandedMenu': () => import('./ru-RU/AllCategoriesExpandedMenu'),
        'MiniCart': () => import('./ru-RU/MiniCart'),
        'Language': () => import('./ru-RU/Language'),
        'productDetail': () => import('./ru-RU/productDetail'),
        'AddressBook': () => import('./ru-RU/AddressBook'),
        'Cart': () => import('./ru-RU/Cart'),
        'CheckoutAddress': () => import('./ru-RU/CheckoutAddress'),
        'Checkout': () => import('./ru-RU/Checkout'),
        'Payment': () => import('./ru-RU/Payment'),
        'MultipleShipmentTable': () => import('./ru-RU/MultipleShipmentTable'),
        'Shipping': () => import('./ru-RU/Shipping'),
        'OrderConfirmation': () => import('./ru-RU/OrderConfirmation'),
        'SignInPage': () => import('./ru-RU/SignInPage'),
        'Account': () => import('./ru-RU/Account'),
        'AccountSummary': () => import('./ru-RU/AccountSummary'),
        'ChangePasswordSection': () => import('./ru-RU/ChangePasswordSection'),
        'PersonalInformationSection': () => import('./ru-RU/PersonalInformationSection'),
        'WelcomeUserSection': () => import('./ru-RU/WelcomeUserSection'),
        'AdminTools': () => import('./ru-RU/AdminTools'),
        'BuyerOrganizationAdminTools': () => import('./ru-RU/BuyerOrganizationAdminTools'),
        'ApprovalsManagement': () => import('./ru-RU/ApprovalsManagement'),
        'BuyerOrganizationRegistration': () => import('./ru-RU/BuyerOrganizationRegistration'),
        'BuyerUserRegistration': () => import('./ru-RU/BuyerUserRegistration'),
        'Dashboard': () => import('./ru-RU/Dashboard'),
        'Order': () => import('./ru-RU/Order'),
        'Category': () => import('./ru-RU/Category'),
        'AccountLinksGridView': () => import('./ru-RU/AccountLinksGridView'),
        'AccountLinks': () => import('./ru-RU/AccountLinks'),
        'MyAccount': () => import('./ru-RU/MyAccount'),
        'AddressCard': () => import('./ru-RU/AddressCard'),
        'AddressForm': () => import('./ru-RU/AddressForm'),
        'ChangePassword': () => import('./ru-RU/ChangePassword'),
        'CheckoutProfile': () => import('./ru-RU/CheckoutProfile'),
        'ChildPimCategories': () => import('./ru-RU/ChildPimCategories'),
        'Confirmation': () => import('./ru-RU/Confirmation'),
        'FeaturedCard': () => import('./ru-RU/FeaturedCard'),
        'ForgotPassword': () => import('./ru-RU/ForgotPassword'),
        'PriceDisplay': () => import('./ru-RU/PriceDisplay'),
        'error-message': () => import('./ru-RU/error-message'),
        'success-message': () => import('./ru-RU/success-message'),
        'OrderBillingInfo': () => import('./ru-RU/OrderBillingInfo'),
        'OrderDetails': () => import('./ru-RU/OrderDetails'),
        'OrderDiscountSummary': () => import('./ru-RU/OrderDiscountSummary'),
        'OrderItemTable': () => import('./ru-RU/OrderItemTable'),
        'OrderLinks': () => import('./ru-RU/OrderLinks'),
        'OrderPaymentInfo': () => import('./ru-RU/OrderPaymentInfo'),
        'OrderShippingInfo': () => import('./ru-RU/OrderShippingInfo'),
        'OrderTotalSummary': () => import('./ru-RU/OrderTotalSummary'),
        'PaymentMethodContainer': () => import('./ru-RU/PaymentMethodContainer'),
        'PaymentInfoCard': () => import('./ru-RU/PaymentInfoCard'),
        'PaymentInfoList': () => import('./ru-RU/PaymentInfoList'),
        'PaymentMethodSelection': () => import('./ru-RU/PaymentMethodSelection'),
        'PersonalInformation': () => import('./ru-RU/PersonalInformation'),
        'ProductFilter': () => import('./ru-RU/ProductFilter'),
        'ProductGrid': () => import('./ru-RU/ProductGrid'),
        'PurchaseOrderNumber': () => import('./ru-RU/PurchaseOrderNumber'),
        'PurchaseOrderSelection': () => import('./ru-RU/PurchaseOrderSelection'),
        'RecurringOrderInfo': () => import('./ru-RU/RecurringOrderInfo'),
        'RegistrationLayout': () => import('./ru-RU/RegistrationLayout'),
        'RegistrationB2BLayout': () => import('./ru-RU/RegistrationB2BLayout'),
        'ResetPassword': () => import('./ru-RU/ResetPassword'),
        'SearchBar': () => import('./ru-RU/SearchBar'),
        'SessionError': () => import('./ru-RU/SessionError'),
        'SignIn': () => import('./ru-RU/SignIn'),
        'Pickup': () => import('./ru-RU/Pickup'),
        'CommerceEnvironment': () => import('./ru-RU/CommerceEnvironment'),
        'RequisitionLists': () => import('./ru-RU/RequisitionLists'),
        'RequisitionListItems': () => import('./ru-RU/RequisitionListItems'),
        'InprogressOrders': () => import('./ru-RU/InprogressOrders'),
        'InprogressItems': () => import('./ru-RU/InprogressItems'),
        'commonTable': () => import('./ru-RU/commonTable'),
        'WishList': () => import('./ru-RU/WishList'),
        'OrderMethod': () => import('./ru-RU/OrderMethod'),
        'compare': () => import('./ru-RU/compare'),
        'sellers': () => import('./ru-RU/sellers'),
        'StoreLocator': () => import('./ru-RU/StoreLocator'),
        'ProgressIndicator': () => import('./ru-RU/ProgressIndicator'),
        'Files': () => import('./ru-RU/Files'),
        'PasswordInput': () => import('./ru-RU/PasswordInput'),
        'Common': () => import('./ru-RU/Common'),
    },
    'zh-CN': {
        'Routes': () => import('./zh-CN/Routes'),
        'Footer': () => import('./zh-CN/Footer'),
        'Header': () => import('./zh-CN/Header'),
        'MegaMenu': () => import('./zh-CN/MegaMenu'),
        'AllCategoriesExpandedMenu': () => import('./zh-CN/AllCategoriesExpandedMenu'),
        'MiniCart': () => import('./zh-CN/MiniCart'),
        'Language': () => import('./zh-CN/Language'),
        'productDetail': () => import('./zh-CN/productDetail'),
        'AddressBook': () => import('./zh-CN/AddressBook'),
        'Cart': () => import('./zh-CN/Cart'),
        'CheckoutAddress': () => import('./zh-CN/CheckoutAddress'),
        'Checkout': () => import('./zh-CN/Checkout'),
        'Payment': () => import('./zh-CN/Payment'),
        'MultipleShipmentTable': () => import('./zh-CN/MultipleShipmentTable'),
        'Shipping': () => import('./zh-CN/Shipping'),
        'OrderConfirmation': () => import('./zh-CN/OrderConfirmation'),
        'SignInPage': () => import('./zh-CN/SignInPage'),
        'Account': () => import('./zh-CN/Account'),
        'AccountSummary': () => import('./zh-CN/AccountSummary'),
        'ChangePasswordSection': () => import('./zh-CN/ChangePasswordSection'),
        'PersonalInformationSection': () => import('./zh-CN/PersonalInformationSection'),
        'WelcomeUserSection': () => import('./zh-CN/WelcomeUserSection'),
        'AdminTools': () => import('./zh-CN/AdminTools'),
        'BuyerOrganizationAdminTools': () => import('./zh-CN/BuyerOrganizationAdminTools'),
        'ApprovalsManagement': () => import('./zh-CN/ApprovalsManagement'),
        'BuyerOrganizationRegistration': () => import('./zh-CN/BuyerOrganizationRegistration'),
        'BuyerUserRegistration': () => import('./zh-CN/BuyerUserRegistration'),
        'Dashboard': () => import('./zh-CN/Dashboard'),
        'Order': () => import('./zh-CN/Order'),
        'Category': () => import('./zh-CN/Category'),
        'AccountLinksGridView': () => import('./zh-CN/AccountLinksGridView'),
        'AccountLinks': () => import('./zh-CN/AccountLinks'),
        'MyAccount': () => import('./zh-CN/MyAccount'),
        'AddressCard': () => import('./zh-CN/AddressCard'),
        'AddressForm': () => import('./zh-CN/AddressForm'),
        'ChangePassword': () => import('./zh-CN/ChangePassword'),
        'CheckoutProfile': () => import('./zh-CN/CheckoutProfile'),
        'ChildPimCategories': () => import('./zh-CN/ChildPimCategories'),
        'Confirmation': () => import('./zh-CN/Confirmation'),
        'FeaturedCard': () => import('./zh-CN/FeaturedCard'),
        'ForgotPassword': () => import('./zh-CN/ForgotPassword'),
        'PriceDisplay': () => import('./zh-CN/PriceDisplay'),
        'error-message': () => import('./zh-CN/error-message'),
        'success-message': () => import('./zh-CN/success-message'),
        'OrderBillingInfo': () => import('./zh-CN/OrderBillingInfo'),
        'OrderDetails': () => import('./zh-CN/OrderDetails'),
        'OrderDiscountSummary': () => import('./zh-CN/OrderDiscountSummary'),
        'OrderItemTable': () => import('./zh-CN/OrderItemTable'),
        'OrderLinks': () => import('./zh-CN/OrderLinks'),
        'OrderPaymentInfo': () => import('./zh-CN/OrderPaymentInfo'),
        'OrderShippingInfo': () => import('./zh-CN/OrderShippingInfo'),
        'OrderTotalSummary': () => import('./zh-CN/OrderTotalSummary'),
        'PaymentMethodContainer': () => import('./zh-CN/PaymentMethodContainer'),
        'PaymentInfoCard': () => import('./zh-CN/PaymentInfoCard'),
        'PaymentInfoList': () => import('./zh-CN/PaymentInfoList'),
        'PaymentMethodSelection': () => import('./zh-CN/PaymentMethodSelection'),
        'PersonalInformation': () => import('./zh-CN/PersonalInformation'),
        'ProductFilter': () => import('./zh-CN/ProductFilter'),
        'ProductGrid': () => import('./zh-CN/ProductGrid'),
        'PurchaseOrderNumber': () => import('./zh-CN/PurchaseOrderNumber'),
        'PurchaseOrderSelection': () => import('./zh-CN/PurchaseOrderSelection'),
        'RecurringOrderInfo': () => import('./zh-CN/RecurringOrderInfo'),
        'RegistrationLayout': () => import('./zh-CN/RegistrationLayout'),
        'RegistrationB2BLayout': () => import('./zh-CN/RegistrationB2BLayout'),
        'ResetPassword': () => import('./zh-CN/ResetPassword'),
        'SearchBar': () => import('./zh-CN/SearchBar'),
        'SessionError': () => import('./zh-CN/SessionError'),
        'SignIn': () => import('./zh-CN/SignIn'),
        'Pickup': () => import('./zh-CN/Pickup'),
        'CommerceEnvironment': () => import('./zh-CN/CommerceEnvironment'),
        'RequisitionLists': () => import('./zh-CN/RequisitionLists'),
        'RequisitionListItems': () => import('./zh-CN/RequisitionListItems'),
        'InprogressOrders': () => import('./zh-CN/InprogressOrders'),
        'InprogressItems': () => import('./zh-CN/InprogressItems'),
        'commonTable': () => import('./zh-CN/commonTable'),
        'WishList': () => import('./zh-CN/WishList'),
        'OrderMethod': () => import('./zh-CN/OrderMethod'),
        'compare': () => import('./zh-CN/compare'),
        'sellers': () => import('./zh-CN/sellers'),
        'StoreLocator': () => import('./zh-CN/StoreLocator'),
        'ProgressIndicator': () => import('./zh-CN/ProgressIndicator'),
        'Files': () => import('./zh-CN/Files'),
        'PasswordInput': () => import('./zh-CN/PasswordInput'),
        'Common': () => import('./zh-CN/Common'),
    },
    'zh-TW': {
        'Routes': () => import('./zh-TW/Routes'),
        'Footer': () => import('./zh-TW/Footer'),
        'Header': () => import('./zh-TW/Header'),
        'MegaMenu': () => import('./zh-TW/MegaMenu'),
        'AllCategoriesExpandedMenu': () => import('./zh-TW/AllCategoriesExpandedMenu'),
        'MiniCart': () => import('./zh-TW/MiniCart'),
        'Language': () => import('./zh-TW/Language'),
        'productDetail': () => import('./zh-TW/productDetail'),
        'AddressBook': () => import('./zh-TW/AddressBook'),
        'Cart': () => import('./zh-TW/Cart'),
        'CheckoutAddress': () => import('./zh-TW/CheckoutAddress'),
        'Checkout': () => import('./zh-TW/Checkout'),
        'Payment': () => import('./zh-TW/Payment'),
        'MultipleShipmentTable': () => import('./zh-TW/MultipleShipmentTable'),
        'Shipping': () => import('./zh-TW/Shipping'),
        'OrderConfirmation': () => import('./zh-TW/OrderConfirmation'),
        'SignInPage': () => import('./zh-TW/SignInPage'),
        'Account': () => import('./zh-TW/Account'),
        'AccountSummary': () => import('./zh-TW/AccountSummary'),
        'ChangePasswordSection': () => import('./zh-TW/ChangePasswordSection'),
        'PersonalInformationSection': () => import('./zh-TW/PersonalInformationSection'),
        'WelcomeUserSection': () => import('./zh-TW/WelcomeUserSection'),
        'AdminTools': () => import('./zh-TW/AdminTools'),
        'BuyerOrganizationAdminTools': () => import('./zh-TW/BuyerOrganizationAdminTools'),
        'ApprovalsManagement': () => import('./zh-TW/ApprovalsManagement'),
        'BuyerOrganizationRegistration': () => import('./zh-TW/BuyerOrganizationRegistration'),
        'BuyerUserRegistration': () => import('./zh-TW/BuyerUserRegistration'),
        'Dashboard': () => import('./zh-TW/Dashboard'),
        'Order': () => import('./zh-TW/Order'),
        'Category': () => import('./zh-TW/Category'),
        'AccountLinksGridView': () => import('./zh-TW/AccountLinksGridView'),
        'AccountLinks': () => import('./zh-TW/AccountLinks'),
        'MyAccount': () => import('./zh-TW/MyAccount'),
        'AddressCard': () => import('./zh-TW/AddressCard'),
        'AddressForm': () => import('./zh-TW/AddressForm'),
        'ChangePassword': () => import('./zh-TW/ChangePassword'),
        'CheckoutProfile': () => import('./zh-TW/CheckoutProfile'),
        'ChildPimCategories': () => import('./zh-TW/ChildPimCategories'),
        'Confirmation': () => import('./zh-TW/Confirmation'),
        'FeaturedCard': () => import('./zh-TW/FeaturedCard'),
        'ForgotPassword': () => import('./zh-TW/ForgotPassword'),
        'PriceDisplay': () => import('./zh-TW/PriceDisplay'),
        'error-message': () => import('./zh-TW/error-message'),
        'success-message': () => import('./zh-TW/success-message'),
        'OrderBillingInfo': () => import('./zh-TW/OrderBillingInfo'),
        'OrderDetails': () => import('./zh-TW/OrderDetails'),
        'OrderDiscountSummary': () => import('./zh-TW/OrderDiscountSummary'),
        'OrderItemTable': () => import('./zh-TW/OrderItemTable'),
        'OrderLinks': () => import('./zh-TW/OrderLinks'),
        'OrderPaymentInfo': () => import('./zh-TW/OrderPaymentInfo'),
        'OrderShippingInfo': () => import('./zh-TW/OrderShippingInfo'),
        'OrderTotalSummary': () => import('./zh-TW/OrderTotalSummary'),
        'PaymentMethodContainer': () => import('./zh-TW/PaymentMethodContainer'),
        'PaymentInfoCard': () => import('./zh-TW/PaymentInfoCard'),
        'PaymentInfoList': () => import('./zh-TW/PaymentInfoList'),
        'PaymentMethodSelection': () => import('./zh-TW/PaymentMethodSelection'),
        'PersonalInformation': () => import('./zh-TW/PersonalInformation'),
        'ProductFilter': () => import('./zh-TW/ProductFilter'),
        'ProductGrid': () => import('./zh-TW/ProductGrid'),
        'PurchaseOrderNumber': () => import('./zh-TW/PurchaseOrderNumber'),
        'PurchaseOrderSelection': () => import('./zh-TW/PurchaseOrderSelection'),
        'RecurringOrderInfo': () => import('./zh-TW/RecurringOrderInfo'),
        'RegistrationLayout': () => import('./zh-TW/RegistrationLayout'),
        'RegistrationB2BLayout': () => import('./zh-TW/RegistrationB2BLayout'),
        'ResetPassword': () => import('./zh-TW/ResetPassword'),
        'SearchBar': () => import('./zh-TW/SearchBar'),
        'SessionError': () => import('./zh-TW/SessionError'),
        'SignIn': () => import('./zh-TW/SignIn'),
        'Pickup': () => import('./zh-TW/Pickup'),
        'CommerceEnvironment': () => import('./zh-TW/CommerceEnvironment'),
        'RequisitionLists': () => import('./zh-TW/RequisitionLists'),
        'RequisitionListItems': () => import('./zh-TW/RequisitionListItems'),
        'InprogressOrders': () => import('./zh-TW/InprogressOrders'),
        'InprogressItems': () => import('./zh-TW/InprogressItems'),
        'commonTable': () => import('./zh-TW/commonTable'),
        'WishList': () => import('./zh-TW/WishList'),
        'OrderMethod': () => import('./zh-TW/OrderMethod'),
        'compare': () => import('./zh-TW/compare'),
        'sellers': () => import('./zh-TW/sellers'),
        'StoreLocator': () => import('./zh-TW/StoreLocator'),
        'ProgressIndicator': () => import('./zh-TW/ProgressIndicator'),
        'Files': () => import('./zh-TW/Files'),
        'PasswordInput': () => import('./zh-TW/PasswordInput'),
        'Common': () => import('./zh-TW/Common'),
    },
};

export interface TranslationTable {'Routes': {'Search': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'Login': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'Account': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'ForgotPassword': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'ResetPassword': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'Registration': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'Cart': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'CheckOut': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'AddressBook': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'WishLists': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'CheckoutProfiles': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'OrderConfirmation': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'OrderHistory': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'OrderDetails': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'SessionError': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'Error404': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'Error500': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'StoreLocator': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'CompareProducts': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'BuyerUserRegistration': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'BuyerOrganizationRegistration': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'RequisitionLists': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'RequisitionListDetails': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'RequisitionListsUploadLogs': {'route': {t: (args?: [...ArgTypes[]]) => string;};
'title': {t: (args?: [...ArgTypes[]]) => string;};
'description': {t: (args?: [...ArgTypes[]]) => string;};
'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'RecurringOrders': {/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'route': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'title': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'description': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'MyEquipment': {/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'route': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'title': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'description': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'Agreements': {/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'route': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'title': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'description': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'Warranties': {/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'route': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'title': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'description': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'ServiceRequests': {/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'route': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'title': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'description': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'Quotes': {/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'route': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'title': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'description': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'Invoices': {/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'route': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'title': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'description': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'Subscriptions': {/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'route': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'title': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'description': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'ApprovalsManagement': {/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'route': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'title': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'description': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'BuyerManagement': {/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'route': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'title': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'description': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'BuyerManagementCreate': {/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'route': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'title': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'description': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'BuyerManagementEdit': {/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'route': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'title': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'description': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'OrganizationManagement': {/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'route': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'title': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'description': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'OrganizationManagementCreate': {/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'route': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'title': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'description': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'OrganizationManagementEdit': {/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'route': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'title': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'description': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'BuyerApprovalDetails': {/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'route': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'title': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'description': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
'OrderApprovalDetails': {/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'route': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'title': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'description': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'keywords': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'Footer': {'CompanyLinks': {'Label': {t: (args?: [...ArgTypes[]]) => string;};
'OurStory': {t: (args?: [...ArgTypes[]]) => string;};
'Careers': {t: (args?: [...ArgTypes[]]) => string;};
};
'CopyrightFull': {t: ({date}: TemplateArgs) => string;};
'Copyright': {t: (args?: [...ArgTypes[]]) => string;};
'CustomerService': {'Label': {t: (args?: [...ArgTypes[]]) => string;};
'ContactUs': {t: (args?: [...ArgTypes[]]) => string;};
'ContactUsDescription': {t: (args?: [...ArgTypes[]]) => string;};
'PrivacyPolicy': {t: (args?: [...ArgTypes[]]) => string;};
};
'FollowUs': {t: (args?: [...ArgTypes[]]) => string;};
'Description': {t: (args?: [...ArgTypes[]]) => string;};
'DisabledMessage': {t: (args?: [...ArgTypes[]]) => string;};
};
'Header': {'Actions': {'SignIn': {t: (args?: [...ArgTypes[]]) => string;};
'SignInRegister': {t: (args?: [...ArgTypes[]]) => string;};
'SignRegSeller': {t: (args?: [...ArgTypes[]]) => string;};
'WelcomeFirstName': {t: ({firstName}: TemplateArgs) => string;};
'WelcomeNoFirstName': {t: (args?: [...ArgTypes[]]) => string;};
'WishList': {t: (args?: [...ArgTypes[]]) => string;};
'YourAccount': {t: (args?: [...ArgTypes[]]) => string;};
'Account': {t: (args?: [...ArgTypes[]]) => string;};
};
'AccountPopper': {'Welcome': {t: ({firstName, lastName}: TemplateArgs) => string;};
'Organization': {t: (args?: [...ArgTypes[]]) => string;};
'Contract': {t: (args?: [...ArgTypes[]]) => string;};
'Edit': {t: (args?: [...ArgTypes[]]) => string;};
'AccountSetting': {t: (args?: [...ArgTypes[]]) => string;};
'SignOut': {t: (args?: [...ArgTypes[]]) => string;};
'Cart': {t: ({count}: TemplateArgs) => string;};
'Done': {t: (args?: [...ArgTypes[]]) => string;};
};
'StoreClosed': {/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'Msg': {t: ({store}: TemplateArgs) => string;};
};
};
'MegaMenu': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Actions': {'ViewAll': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'AllCategoriesExpandedMenu': {'AllCategoriesLabel': {t: (args?: [...ArgTypes[]]) => string;};
};
'MiniCart': {'Items': {t: ({count}: TemplateArgs) => string;};
'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Subtotal': {t: ({count, total}: TemplateArgs) => string;};
'Empty': {t: (args?: [...ArgTypes[]]) => string;};
'AndNMore': {t: ({count}: TemplateArgs) => string;};
'Actions': {'Cart': {t: (args?: [...ArgTypes[]]) => string;};
'CheckOut': {t: (args?: [...ArgTypes[]]) => string;};
'ViewOrderDetails': {t: (args?: [...ArgTypes[]]) => string;};
};
'MyOrder': {t: (args?: [...ArgTypes[]]) => string;};
'PrivateOrder': {t: (args?: [...ArgTypes[]]) => string;};
'SharedOrderConributor': {t: (args?: [...ArgTypes[]]) => string;};
'SharedOrderAdmin': {t: (args?: [...ArgTypes[]]) => string;};
};
'Language': {'-1': {t: (args?: [...ArgTypes[]]) => string;};
'-2': {t: (args?: [...ArgTypes[]]) => string;};
'-3': {t: (args?: [...ArgTypes[]]) => string;};
'-4': {t: (args?: [...ArgTypes[]]) => string;};
'-5': {t: (args?: [...ArgTypes[]]) => string;};
'-6': {t: (args?: [...ArgTypes[]]) => string;};
'-7': {t: (args?: [...ArgTypes[]]) => string;};
'-8': {t: (args?: [...ArgTypes[]]) => string;};
'-10': {t: (args?: [...ArgTypes[]]) => string;};
'-20': {t: (args?: [...ArgTypes[]]) => string;};
'-21': {t: (args?: [...ArgTypes[]]) => string;};
'-9': {t: (args?: [...ArgTypes[]]) => string;};
'-22': {t: (args?: [...ArgTypes[]]) => string;};
'-23': {t: (args?: [...ArgTypes[]]) => string;};
};
'productDetail': {'Quantity': {t: (args?: [...ArgTypes[]]) => string;};
'QtyError': {t: (args?: [...ArgTypes[]]) => string;};
'Availability': {t: (args?: [...ArgTypes[]]) => string;};
'AddToCart': {t: (args?: [...ArgTypes[]]) => string;};
'Seller': {t: ({seller}: TemplateArgs) => string;};
'SellerSimple': {t: ({seller}: TemplateArgs) => string;};
'AddBundleToCart': {t: (args?: [...ArgTypes[]]) => string;};
'AddKitToCart': {t: (args?: [...ArgTypes[]]) => string;};
'AddKitToSharedOrder': {t: (args?: [...ArgTypes[]]) => string;};
'AddBundleToSharedOrder': {t: (args?: [...ArgTypes[]]) => string;};
'SelectAttributes': {t: (args?: [...ArgTypes[]]) => string;};
'Description': {t: (args?: [...ArgTypes[]]) => string;};
'ProductDetails': {t: (args?: [...ArgTypes[]]) => string;};
'Attachments': {t: (args?: [...ArgTypes[]]) => string;};
'AllAttachments': {t: (args?: [...ArgTypes[]]) => string;};
'ViewAll': {t: (args?: [...ArgTypes[]]) => string;};
'Images': {t: (args?: [...ArgTypes[]]) => string;};
'Videos': {t: (args?: [...ArgTypes[]]) => string;};
'Documents': {t: (args?: [...ArgTypes[]]) => string;};
'PricePending': {t: (args?: [...ArgTypes[]]) => string;};
'addToCartErrorMsg': {t: (args?: [...ArgTypes[]]) => string;};
'any': {t: (args?: [...ArgTypes[]]) => string;};
'showAttributes': {t: (args?: [...ArgTypes[]]) => string;};
'attributeFilter': {t: (args?: [...ArgTypes[]]) => string;};
'addToCurrentOrder': {t: (args?: [...ArgTypes[]]) => string;};
'addToSharedOrder': {t: (args?: [...ArgTypes[]]) => string;};
'SignIn': {t: (args?: [...ArgTypes[]]) => string;};
'noproductsToDisplay': {t: (args?: [...ArgTypes[]]) => string;};
'SKU': {t: (args?: [...ArgTypes[]]) => string;};
'skuLabel': {t: ({value}: TemplateArgs) => string;};
'descAttrLabel': {t: ({name, values}: TemplateArgs) => string;};
'PRODUCTSKU': {t: (args?: [...ArgTypes[]]) => string;};
'PRODUCT': {t: (args?: [...ArgTypes[]]) => string;};
'PRODUCTNAME': {t: (args?: [...ArgTypes[]]) => string;};
'QUANTITY': {t: (args?: [...ArgTypes[]]) => string;};
'SelectAnOption': {t: (args?: [...ArgTypes[]]) => string;};
'Unconfigured': {t: (args?: [...ArgTypes[]]) => string;};
'Unavailable': {t: (args?: [...ArgTypes[]]) => string;};
'InStock': {t: (args?: [...ArgTypes[]]) => string;};
'Price': {t: (args?: [...ArgTypes[]]) => string;};
'Online_Availability': {t: (args?: [...ArgTypes[]]) => string;};
'recommendedProdTitle': {t: (args?: [...ArgTypes[]]) => string;};
'someWithNoSkus': {t: (args?: [...ArgTypes[]]) => string;};
'someWithNoAvlSelZero': {t: (args?: [...ArgTypes[]]) => string;};
'someWithNoAvlPlsRemove': {t: (args?: [...ArgTypes[]]) => string;};
'someWithNotEnough': {t: (args?: [...ArgTypes[]]) => string;};
'selectSomething': {t: (args?: [...ArgTypes[]]) => string;};
'noAttributes': {t: (args?: [...ArgTypes[]]) => string;};
'noproductsKit': {t: (args?: [...ArgTypes[]]) => string;};
'addToRL': {t: (args?: [...ArgTypes[]]) => string;};
'createRL': {t: (args?: [...ArgTypes[]]) => string;};
'addToWL': {t: (args?: [...ArgTypes[]]) => string;};
'createWL': {t: (args?: [...ArgTypes[]]) => string;};
'QuantityValue': {t: ({quantity}: TemplateArgs) => string;};
'hideAttrs': {t: (args?: [...ArgTypes[]]) => string;};
'removeFromSelection': {t: (args?: [...ArgTypes[]]) => string;};
'chooseQuantity': {t: (args?: [...ArgTypes[]]) => string;};
'noAttrs': {t: (args?: [...ArgTypes[]]) => string;};
'detailsNotAvailable': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'descAttrLabelWithTagValues': {t: ({name}: TemplateArgs) => string;};
};
'AddressBook': {'AddrMsg': {t: (args?: [...ArgTypes[]]) => string;};
'AddButton': {t: (args?: [...ArgTypes[]]) => string;};
'Title': {t: (args?: [...ArgTypes[]]) => string;};
'FilterLabel': {t: (args?: [...ArgTypes[]]) => string;};
'NoAddrMsg': {t: (args?: [...ArgTypes[]]) => string;};
'NoAddrForFilterMsg': {t: (args?: [...ArgTypes[]]) => string;};
'ShowAllLabel': {t: (args?: [...ArgTypes[]]) => string;};
'ShippingLabel': {t: (args?: [...ArgTypes[]]) => string;};
'BillingLabel': {t: (args?: [...ArgTypes[]]) => string;};
'ShippingBillingLabel': {t: (args?: [...ArgTypes[]]) => string;};
'CreateAddress': {t: (args?: [...ArgTypes[]]) => string;};
'SaveChanges': {t: (args?: [...ArgTypes[]]) => string;};
'Cancel': {t: (args?: [...ArgTypes[]]) => string;};
'EditAddress': {t: (args?: [...ArgTypes[]]) => string;};
};
'Cart': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Msgs': {'Empty': {t: (args?: [...ArgTypes[]]) => string;};
'PromoCode': {t: (args?: [...ArgTypes[]]) => string;};
'ShopNow': {t: (args?: [...ArgTypes[]]) => string;};
};
'Labels': {'PromoCode': {t: (args?: [...ArgTypes[]]) => string;};
'RecurringOrder': {t: (args?: [...ArgTypes[]]) => string;};
'Frequency': {t: (args?: [...ArgTypes[]]) => string;};
'StartDate': {t: (args?: [...ArgTypes[]]) => string;};
'OrderSummary': {t: (args?: [...ArgTypes[]]) => string;};
};
'Actions': {'Checkout': {t: (args?: [...ArgTypes[]]) => string;};
'ContinueShopping': {t: (args?: [...ArgTypes[]]) => string;};
'Apply': {t: (args?: [...ArgTypes[]]) => string;};
'CheckoutWithProfile': {t: (args?: [...ArgTypes[]]) => string;};
};
'Availability': {'NOT_AVAIL_PICKUP': {t: ({storeName}: TemplateArgs) => string;};
'AVAIL_PICKUP': {t: ({storeName}: TemplateArgs) => string;};
'NOT_AVAIL_DELIVERY': {t: (args?: [...ArgTypes[]]) => string;};
'AVAIL_DELIVERY': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'CheckoutAddress': {'Actions': {'Submit': {t: (args?: [...ArgTypes[]]) => string;};
'Cancel': {t: (args?: [...ArgTypes[]]) => string;};
};
'Payment': {'ChooseFirst': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'Checkout': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'CheckoutProfileTitle': {t: (args?: [...ArgTypes[]]) => string;};
'Labels': {'shipping': {t: (args?: [...ArgTypes[]]) => string;};
'payment': {t: (args?: [...ArgTypes[]]) => string;};
'review': {t: (args?: [...ArgTypes[]]) => string;};
'pickup-store': {t: (args?: [...ArgTypes[]]) => string;};
'pickup': {t: (args?: [...ArgTypes[]]) => string;};
};
'Actions': {'ReturnCart': {t: (args?: [...ArgTypes[]]) => string;};
'BackTo': {t: ({step}: TemplateArgs) => string;};
'ContinueTo': {t: ({step}: TemplateArgs) => string;};
'PlaceOrder': {t: (args?: [...ArgTypes[]]) => string;};
};
'PickupNotAvailable': {t: (args?: [...ArgTypes[]]) => string;};
};
'Payment': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'TitleMulti': {t: ({total}: TemplateArgs) => string;};
'Msgs': {'InvalidFormat': {t: (args?: [...ArgTypes[]]) => string;};
'SelectShippingAddress': {t: (args?: [...ArgTypes[]]) => string;};
'SelectBillingAddress': {t: (args?: [...ArgTypes[]]) => string;};
};
'Labels': {'UseMultiple': {t: (args?: [...ArgTypes[]]) => string;};
'PaymentMethod': {t: ({number}: TemplateArgs) => string;};
'AddNewAddress': {t: (args?: [...ArgTypes[]]) => string;};
'BillingAddress': {t: (args?: [...ArgTypes[]]) => string;};
};
'Actions': {'Back': {t: (args?: [...ArgTypes[]]) => string;};
'BackToPickup': {t: (args?: [...ArgTypes[]]) => string;};
'Next': {t: (args?: [...ArgTypes[]]) => string;};
'Finish': {t: (args?: [...ArgTypes[]]) => string;};
'SaveAndAdd': {t: (args?: [...ArgTypes[]]) => string;};
'AddAnotherPayMethod': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'MultipleShipmentTable': {'Labels': {'ItemDetails': {t: (args?: [...ArgTypes[]]) => string;};
'SKU': {t: (args?: [...ArgTypes[]]) => string;};
'Quantity': {t: (args?: [...ArgTypes[]]) => string;};
'ShippingDetails': {t: (args?: [...ArgTypes[]]) => string;};
'SelectShippingAddressAndMethod': {t: (args?: [...ArgTypes[]]) => string;};
'ChangeSelection': {t: (args?: [...ArgTypes[]]) => string;};
'nItemsSel': {t: ({n}: TemplateArgs) => string;};
'noItems': {t: (args?: [...ArgTypes[]]) => string;};
'SelectAll': {t: (args?: [...ArgTypes[]]) => string;};
'OrderItems': {t: ({n}: TemplateArgs) => string;};
'Select': {t: (args?: [...ArgTypes[]]) => string;};
'OutOfStock': {t: (args?: [...ArgTypes[]]) => string;};
'SelInvalid': {t: (args?: [...ArgTypes[]]) => string;};
};
'Msgs': {'MissingSelection': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'Shipping': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Labels': {'UseMultiple': {t: (args?: [...ArgTypes[]]) => string;};
'ShippingAddress': {t: (args?: [...ArgTypes[]]) => string;};
'ShippingMethod': {t: (args?: [...ArgTypes[]]) => string;};
'SelectShippingMethod': {t: (args?: [...ArgTypes[]]) => string;};
'AddNewAddress': {t: (args?: [...ArgTypes[]]) => string;};
'EditAddress': {t: (args?: [...ArgTypes[]]) => string;};
'SelectedCount': {t: ({selected, all}: TemplateArgs) => string;};
'ItemsSelected': {t: ({itemsSelected}: TemplateArgs) => string;};
};
'Actions': {'SelectAddress': {t: (args?: [...ArgTypes[]]) => string;};
'CreateNew': {t: (args?: [...ArgTypes[]]) => string;};
'Next': {t: (args?: [...ArgTypes[]]) => string;};
'Confirm': {t: (args?: [...ArgTypes[]]) => string;};
'Back': {t: (args?: [...ArgTypes[]]) => string;};
'Done': {t: (args?: [...ArgTypes[]]) => string;};
'Cancel': {t: (args?: [...ArgTypes[]]) => string;};
'SelectShippingAddress': {t: (args?: [...ArgTypes[]]) => string;};
};
'Msgs': {'SelectExisting': {t: (args?: [...ArgTypes[]]) => string;};
'UseSavedAddress': {t: (args?: [...ArgTypes[]]) => string;};
'SelectShippingAddress': {t: (args?: [...ArgTypes[]]) => string;};
'Incomplete': {t: (args?: [...ArgTypes[]]) => string;};
'SelectOrCreateAddress': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SelectShipMethod': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'OrderConfirmation': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Msgs': {'Heading': {t: (args?: [...ArgTypes[]]) => string;};
'Pending': {t: (args?: [...ArgTypes[]]) => string;};
'OrderNumber': {t: ({orderId}: TemplateArgs) => string;};
'Details': {t: ({emails}: TemplateArgs) => string;};
'PendingDetails': {t: (args?: [...ArgTypes[]]) => string;};
'ThankYou': {t: ({storeName}: TemplateArgs) => string;};
};
};
'SignInPage': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
};
'Account': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
};
'AccountSummary': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
};
'ChangePasswordSection': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'CurrentPasswordLabel': {t: (args?: [...ArgTypes[]]) => string;};
'NewPasswordLabel': {t: (args?: [...ArgTypes[]]) => string;};
'VerifyPasswordLabel': {t: (args?: [...ArgTypes[]]) => string;};
'SaveLabel': {t: (args?: [...ArgTypes[]]) => string;};
'SuccessLabel': {t: (args?: [...ArgTypes[]]) => string;};
'OkLabel': {t: (args?: [...ArgTypes[]]) => string;};
};
'PersonalInformationSection': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Name': {t: (args?: [...ArgTypes[]]) => string;};
'EmailAddress': {t: (args?: [...ArgTypes[]]) => string;};
'PhoneNumber': {t: (args?: [...ArgTypes[]]) => string;};
'Address': {t: (args?: [...ArgTypes[]]) => string;};
'SaveChanges': {t: (args?: [...ArgTypes[]]) => string;};
'UpdateSuccessful': {t: (args?: [...ArgTypes[]]) => string;};
};
'WelcomeUserSection': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Message': {t: (args?: [...ArgTypes[]]) => string;};
};
'AdminTools': {'buyerManagement': {t: (args?: [...ArgTypes[]]) => string;};
'orgAndBuyer': {t: (args?: [...ArgTypes[]]) => string;};
'orgManagement': {t: (args?: [...ArgTypes[]]) => string;};
'buyerManagementDesc': {t: (args?: [...ArgTypes[]]) => string;};
'orgAndBuyerDesc': {t: (args?: [...ArgTypes[]]) => string;};
'orgManagementDesc': {t: (args?: [...ArgTypes[]]) => string;};
'adminTools': {t: (args?: [...ArgTypes[]]) => string;};
};
'BuyerManagement': {/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'buyerList': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'firstName': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'lastName': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'logonId': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'role': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SearchByLogonId': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'access': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'actions': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'noBuyer': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'disableUser': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'enableUser': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'resetPassword': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'enabled': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'disabled': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'include': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'exclude': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SelectedGroups': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'IncludeMemberGroups': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'EncludeMemberGroups': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'AvailableGroups': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Title': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Edit': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'FirstLast': {t: ({firstName, lastName}: TemplateArgs) => string;};
'Actions': {/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Cancel': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Next': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Back': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Save': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Finish': {t: (args?: [...ArgTypes[]]) => string;};
};
'StepperLabels': {/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'AccountInformation': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'ContactInformation': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Roles': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Groups': {t: (args?: [...ArgTypes[]]) => string;};
};
'AccountLabels': {/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'LogonId': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Email': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'FirstName': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'LastName': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'ContactEmail': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Password': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'VerifyPassword': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'AccountPolicy': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SelectAccountPolicy': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'ParentOrgName': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'PhoneNumber': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'PreferredCurrency': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SelectPreferredCurrency': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'PreferredLanguage': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SelectPreferredLanguage': {t: (args?: [...ArgTypes[]]) => string;};
};
'ContactLabels': {/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'AddressLine1': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'AddressLine2': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'City': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Country': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'State': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'ZipCode': {t: (args?: [...ArgTypes[]]) => string;};
};
'RolesLabels': {/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SelectedRolesText': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SelectRoleText': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SearchRoles': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SelectOrganization': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'NoSelectionText': {t: (args?: [...ArgTypes[]]) => string;};
};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SelectRole': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SelectParentOrganization': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SelectUserAccountStatus': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SearchOptions': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'FirstName': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'LastName': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'UserAccountStatus': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'ParentOrganization': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Search': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'ClearResults': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'BuyerCreated': {t: ({logonId}: TemplateArgs) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'BuyerUpdated': {t: ({logonId}: TemplateArgs) => string;};
};
'BuyerOrganizationAdminTools': {/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'BuyerManagement': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'BuyerOrderApproval': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'OrderApproval': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'OrgManagement': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'BuyerManagementDesc': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'BuyerOrderApprovalDesc': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'OrderApprovalDesc': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'OrgManagementDesc': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'AdminTools': {t: (args?: [...ArgTypes[]]) => string;};
};
'ApprovalsManagement': {/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'ApprovalsManagement': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'Approve': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'Reject': {t: (args?: [...ArgTypes[]]) => string;};
'Columns': {/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'ApprovalId': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'Buyer': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'Status': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'Submitted': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'ApprovedOrRejected': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'Actions': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'OrderId': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'TotalPrice': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'OrderName': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'Pending': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'Approved': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'Rejected': {t: (args?: [...ArgTypes[]]) => string;};
};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'NoItem': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'SearchOptions': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SearchByOrder': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SearchByApproval': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'Refresh': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'BuyerFirstName': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'BuyerLastName': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'ApprovalID': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'OrderID': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'StartDate': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'EndDate': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'Status': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'ViewAll': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'ViewPending': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'ViewApproved': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'ViewRejected': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'Search': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'ClearResults': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'InvalidBuyerName': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'BuyersTab': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'OrdersTab': {t: (args?: [...ArgTypes[]]) => string;};
};
'BuyerApprovalDetails': {/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Approve': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Reject': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Submitted': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'CommentsTitle': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'CommentsSubtitle': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'CommentsTextPlaceHolder': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'AddressTitle': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'BuyerDetailsTitle': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'OrgDetailsTitle': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Organization': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'LogonId': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Name': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Email': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'PreferredLanguage': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'PreferredCurrency': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'NoComments': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'FullName': {t: ({firstName, lastName}: TemplateArgs) => string;};
};
'OrderApprovalDetails': {/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Status_W': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Status_M': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Status_N': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'BackToAM': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'OrderDetails': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'OrderNumber': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'OrderedBy': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'OrderDate': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Organization': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Status': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'CommentsTitle': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'CommentsSubtitle': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'CommentsTextPlaceHolder': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Approve': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Reject': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'NoComments': {t: (args?: [...ArgTypes[]]) => string;};
};
'OrganizationManagement': {/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'OrganizationName': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'DistinguishedName': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'OrganizationManagement': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Type': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'NoItem': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'NewOrganization': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'NewOrganizationButtonText': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'OrganizationDetails': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'NameOrganization': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'DescriptionOptional': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'ParentOrg': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'ContactFirstName': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'ContactLastName': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'ContactEmail': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'StreetAddressLine1': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'StreetAddressLine2': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'City': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Country': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'StateProvinceRegion': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'ZipCode': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'ContactInformation': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Roles': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Approvals': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'BackButton': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'NextButton': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'CancelButton': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'FinishButton': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SelectedRoles': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'NoRolesSelectedText': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SelectApprovalTypesAvailableText': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SelectApprovalTypesHeader': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SelectedApprovalTypesHeader': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'NoApprovalTypesSelected': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'NameSearchPlaceholder': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'ParentOrgPlaceholder': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'EntityType_O': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'EntityType_OU': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'ClearResults': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Approval_Inherited_OrderApprovalGroup': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Approval_Inherited_UserRegistrationApprovalGroup': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Approval_OrderApprovalGroup': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Approval_DisableInherited_OrderApprovalGroup': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Approval_UserRegistrationApprovalGroup': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'Approval_DisableInherited_UserRegistrationApprovalGroup': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'OrganizationCreated': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'OrganizationUpdated': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SelectParentOrganization': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'EditOrganization': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'RolesNotModifiable': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SearchRoles': {t: (args?: [...ArgTypes[]]) => string;};
};
'BuyerOrganizationRegistration': {'OrganizationRegistration': {t: (args?: [...ArgTypes[]]) => string;};
'BuyerAdminRegistration': {t: (args?: [...ArgTypes[]]) => string;};
'Title': {t: (args?: [...ArgTypes[]]) => string;};
'AddressDetails': {t: (args?: [...ArgTypes[]]) => string;};
'AddressLineAdd': {t: (args?: [...ArgTypes[]]) => string;};
'Back': {t: (args?: [...ArgTypes[]]) => string;};
'NextRegister': {t: (args?: [...ArgTypes[]]) => string;};
'CompleteRegistration': {t: (args?: [...ArgTypes[]]) => string;};
'HaveAnAccount': {t: (args?: [...ArgTypes[]]) => string;};
'HereAnAccount': {t: (args?: [...ArgTypes[]]) => string;};
'SignIn': {t: (args?: [...ArgTypes[]]) => string;};
'OrganizationName': {t: (args?: [...ArgTypes[]]) => string;};
'AccountPreferences': {t: (args?: [...ArgTypes[]]) => string;};
'Email': {t: (args?: [...ArgTypes[]]) => string;};
'Address1': {t: (args?: [...ArgTypes[]]) => string;};
'Address2': {t: (args?: [...ArgTypes[]]) => string;};
'City': {t: (args?: [...ArgTypes[]]) => string;};
'Country': {t: (args?: [...ArgTypes[]]) => string;};
'State': {t: (args?: [...ArgTypes[]]) => string;};
'ZipCode': {t: (args?: [...ArgTypes[]]) => string;};
'FirstName': {t: (args?: [...ArgTypes[]]) => string;};
'LastName': {t: (args?: [...ArgTypes[]]) => string;};
'LogonId': {t: (args?: [...ArgTypes[]]) => string;};
'Phone': {t: (args?: [...ArgTypes[]]) => string;};
'Password': {t: (args?: [...ArgTypes[]]) => string;};
'Password2': {t: (args?: [...ArgTypes[]]) => string;};
'Language': {t: (args?: [...ArgTypes[]]) => string;};
'Currency': {t: (args?: [...ArgTypes[]]) => string;};
'UseOrganizationAddress': {t: (args?: [...ArgTypes[]]) => string;};
'Submit': {t: (args?: [...ArgTypes[]]) => string;};
'Clear': {t: (args?: [...ArgTypes[]]) => string;};
'Success': {t: (args?: [...ArgTypes[]]) => string;};
'OK': {t: (args?: [...ArgTypes[]]) => string;};
'Msgs': {'InvalidFormat': {t: (args?: [...ArgTypes[]]) => string;};
};
'ZipCodePostalCode': {t: (args?: [...ArgTypes[]]) => string;};
};
'BuyerUserRegistration': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Description': {t: (args?: [...ArgTypes[]]) => string;};
'OrgName': {t: (args?: [...ArgTypes[]]) => string;};
'Email': {t: (args?: [...ArgTypes[]]) => string;};
'Address1': {t: (args?: [...ArgTypes[]]) => string;};
'Address2': {t: (args?: [...ArgTypes[]]) => string;};
'City': {t: (args?: [...ArgTypes[]]) => string;};
'Country': {t: (args?: [...ArgTypes[]]) => string;};
'State': {t: (args?: [...ArgTypes[]]) => string;};
'ZipCode': {t: (args?: [...ArgTypes[]]) => string;};
'FirstName': {t: (args?: [...ArgTypes[]]) => string;};
'LastName': {t: (args?: [...ArgTypes[]]) => string;};
'LogonId': {t: (args?: [...ArgTypes[]]) => string;};
'Phone': {t: (args?: [...ArgTypes[]]) => string;};
'Password': {t: (args?: [...ArgTypes[]]) => string;};
'Password2': {t: (args?: [...ArgTypes[]]) => string;};
'Language': {t: (args?: [...ArgTypes[]]) => string;};
'Currency': {t: (args?: [...ArgTypes[]]) => string;};
'AddressDetails': {t: (args?: [...ArgTypes[]]) => string;};
'AccountPreferences': {t: (args?: [...ArgTypes[]]) => string;};
'HaveAccount': {t: (args?: [...ArgTypes[]]) => string;};
'AddressLineAdd': {t: (args?: [...ArgTypes[]]) => string;};
'SignIn': {t: (args?: [...ArgTypes[]]) => string;};
'Submit': {t: (args?: [...ArgTypes[]]) => string;};
'Clear': {t: (args?: [...ArgTypes[]]) => string;};
'Success': {t: (args?: [...ArgTypes[]]) => string;};
'OK': {t: (args?: [...ArgTypes[]]) => string;};
'Msgs': {'InvalidFormat': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'Dashboard': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'AccountSettings': {t: (args?: [...ArgTypes[]]) => string;};
'PersonalInformation': {t: (args?: [...ArgTypes[]]) => string;};
'AddressBook': {t: (args?: [...ArgTypes[]]) => string;};
'OrderManagement': {t: (args?: [...ArgTypes[]]) => string;};
'OrderHistory': {t: (args?: [...ArgTypes[]]) => string;};
'RecurringOrders': {t: (args?: [...ArgTypes[]]) => string;};
'RequisitionLists': {t: (args?: [...ArgTypes[]]) => string;};
'InprogressOrders': {t: (args?: [...ArgTypes[]]) => string;};
'ApproveOrders': {t: (args?: [...ArgTypes[]]) => string;};
};
'Order': {'noPO': {t: (args?: [...ArgTypes[]]) => string;};
'Status_A': {t: (args?: [...ArgTypes[]]) => string;};
'Status_B': {t: (args?: [...ArgTypes[]]) => string;};
'Status_C': {t: (args?: [...ArgTypes[]]) => string;};
'Status_D': {t: (args?: [...ArgTypes[]]) => string;};
'Status_E': {t: (args?: [...ArgTypes[]]) => string;};
'Status_F': {t: (args?: [...ArgTypes[]]) => string;};
'Status_G': {t: (args?: [...ArgTypes[]]) => string;};
'Status_I': {t: (args?: [...ArgTypes[]]) => string;};
'Status_K': {t: (args?: [...ArgTypes[]]) => string;};
'Status_L': {t: (args?: [...ArgTypes[]]) => string;};
'Status_M': {t: (args?: [...ArgTypes[]]) => string;};
'Status_N': {t: (args?: [...ArgTypes[]]) => string;};
'Status_P': {t: (args?: [...ArgTypes[]]) => string;};
'Status_R': {t: (args?: [...ArgTypes[]]) => string;};
'Status_S': {t: (args?: [...ArgTypes[]]) => string;};
'Status_T': {t: (args?: [...ArgTypes[]]) => string;};
'Status_W': {t: (args?: [...ArgTypes[]]) => string;};
'Status_V': {t: (args?: [...ArgTypes[]]) => string;};
'Status_X': {t: (args?: [...ArgTypes[]]) => string;};
'Status_RTN': {t: (args?: [...ArgTypes[]]) => string;};
'Status_APP': {t: (args?: [...ArgTypes[]]) => string;};
'NotAvailable': {t: (args?: [...ArgTypes[]]) => string;};
'OrderId': {t: (args?: [...ArgTypes[]]) => string;};
'PONumber': {t: (args?: [...ArgTypes[]]) => string;};
'OrderDate': {t: (args?: [...ArgTypes[]]) => string;};
'Schedule': {t: (args?: [...ArgTypes[]]) => string;};
'NextOrder': {t: (args?: [...ArgTypes[]]) => string;};
'Status': {t: (args?: [...ArgTypes[]]) => string;};
'TotalPrice': {t: (args?: [...ArgTypes[]]) => string;};
'Actions': {t: (args?: [...ArgTypes[]]) => string;};
'RowCount': {t: (args?: [...ArgTypes[]]) => string;};
'NoRecord': {t: (args?: [...ArgTypes[]]) => string;};
'InvalidOrderId': {t: (args?: [...ArgTypes[]]) => string;};
'Search': {t: (args?: [...ArgTypes[]]) => string;};
'PageSizeLabel': {t: (args?: [...ArgTypes[]]) => string;};
'FirstPage': {t: (args?: [...ArgTypes[]]) => string;};
'LastPage': {t: (args?: [...ArgTypes[]]) => string;};
'PreviousPage': {t: (args?: [...ArgTypes[]]) => string;};
'NextPage': {t: (args?: [...ArgTypes[]]) => string;};
'OrderHistory': {t: (args?: [...ArgTypes[]]) => string;};
'RecurringOrders': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'RecurringOrder': {t: (args?: [...ArgTypes[]]) => string;};
'Once': {t: (args?: [...ArgTypes[]]) => string;};
'EveryXDAY': {t: ({frequency}: TemplateArgs) => string;};
'EveryXWEE': {t: ({frequency}: TemplateArgs) => string;};
'EveryXMON': {t: ({frequency}: TemplateArgs) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'StateInActive': {t: (args?: [...ArgTypes[]]) => string;};
'StateInactive': {t: (args?: [...ArgTypes[]]) => string;};
'StateActive': {t: (args?: [...ArgTypes[]]) => string;};
'StateExpired': {t: (args?: [...ArgTypes[]]) => string;};
'StateCancelled': {t: (args?: [...ArgTypes[]]) => string;};
'StateCompleted': {t: (args?: [...ArgTypes[]]) => string;};
'StateSuspended': {t: (args?: [...ArgTypes[]]) => string;};
'StatePendingCancel': {t: (args?: [...ArgTypes[]]) => string;};
'TooltipCancel': {t: (args?: [...ArgTypes[]]) => string;};
'TooltipReOrder': {t: (args?: [...ArgTypes[]]) => string;};
'FilterResults': {t: (args?: [...ArgTypes[]]) => string;};
'OrderDetails': {t: (args?: [...ArgTypes[]]) => string;};
'OrderNumber': {t: (args?: [...ArgTypes[]]) => string;};
'HistorySearchPlaceHolder': {t: (args?: [...ArgTypes[]]) => string;};
'HistoryViewDetailTooltip': {t: (args?: [...ArgTypes[]]) => string;};
'Pending': {t: (args?: [...ArgTypes[]]) => string;};
'multiStatus': {t: (args?: [...ArgTypes[]]) => string;};
'BackToOH': {t: (args?: [...ArgTypes[]]) => string;};
'TooltipOrderIdSearch': {t: (args?: [...ArgTypes[]]) => string;};
'purchaseOrder': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'PlacedDate': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'OrderStatus': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'TotalProductPrice': {t: (args?: [...ArgTypes[]]) => string;};
};
'Category': {'notAvailable': {t: (args?: [...ArgTypes[]]) => string;};
};
'AccountLinksGridView': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'AddressBookText': {t: (args?: [...ArgTypes[]]) => string;};
'AddressBookDescription': {t: (args?: [...ArgTypes[]]) => string;};
'OrderHistoryText': {t: (args?: [...ArgTypes[]]) => string;};
'OrderHistoryDescription': {t: (args?: [...ArgTypes[]]) => string;};
'WishListText': {t: (args?: [...ArgTypes[]]) => string;};
'WishListDescription': {t: (args?: [...ArgTypes[]]) => string;};
'CheckoutProfilesText': {t: (args?: [...ArgTypes[]]) => string;};
'CheckoutProfilesDescription': {t: (args?: [...ArgTypes[]]) => string;};
'RequisitionListsText': {t: (args?: [...ArgTypes[]]) => string;};
'RequisitionListsDescription': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'RecurringOrdersText': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: pl-PL & pt-BR */
                    'RecurringOrdersDescription': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'MyEquipmentText': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'MyEquipmentDescription': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'AgreementsText': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'AgreementsDescription': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'WarrantiesText': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'WarrantiesDescription': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'ServiceRequestsText': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'ServiceRequestsDescription': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SubscriptionsText': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'SubscriptionsDescription': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'QuotesText': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'QuotesDescription': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'InvoicesText': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'InvoicesDescription': {t: (args?: [...ArgTypes[]]) => string;};
};
'AccountLinks': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'PersonalInformationText': {t: (args?: [...ArgTypes[]]) => string;};
'PersonalInformationDescription': {t: (args?: [...ArgTypes[]]) => string;};
'AddressBookText': {t: (args?: [...ArgTypes[]]) => string;};
'AddressBookDescription': {t: (args?: [...ArgTypes[]]) => string;};
'DisabledMessage': {t: (args?: [...ArgTypes[]]) => string;};
};
'MyAccount': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'AccountSettings': {t: (args?: [...ArgTypes[]]) => string;};
'PersonalInformation': {t: (args?: [...ArgTypes[]]) => string;};
'AddressBook': {t: (args?: [...ArgTypes[]]) => string;};
'OrderHistory': {t: (args?: [...ArgTypes[]]) => string;};
'Wishlists': {t: (args?: [...ArgTypes[]]) => string;};
'CheckoutProfiles': {t: (args?: [...ArgTypes[]]) => string;};
'AccountAddress': {t: (args?: [...ArgTypes[]]) => string;};
'NoAccountAddress': {t: (args?: [...ArgTypes[]]) => string;};
'ContactInformation': {t: (args?: [...ArgTypes[]]) => string;};
'RecentOrders': {t: (args?: [...ArgTypes[]]) => string;};
'ViewOrders': {t: (args?: [...ArgTypes[]]) => string;};
};
'AddressCard': {'EditButton': {t: (args?: [...ArgTypes[]]) => string;};
'DeleteButton': {t: (args?: [...ArgTypes[]]) => string;};
'Confirm': {t: (args?: [...ArgTypes[]]) => string;};
'Cancel': {t: (args?: [...ArgTypes[]]) => string;};
'UseAddress': {t: (args?: [...ArgTypes[]]) => string;};
};
'AddressForm': {'Msgs': {'InvalidFormat': {t: (args?: [...ArgTypes[]]) => string;};
'InvalidAddressName': {t: (args?: [...ArgTypes[]]) => string;};
};
'Labels': {'FirstName': {t: (args?: [...ArgTypes[]]) => string;};
'LastName': {t: (args?: [...ArgTypes[]]) => string;};
'Address1': {t: (args?: [...ArgTypes[]]) => string;};
'Address2': {t: (args?: [...ArgTypes[]]) => string;};
'City': {t: (args?: [...ArgTypes[]]) => string;};
'Country': {t: (args?: [...ArgTypes[]]) => string;};
'State': {t: (args?: [...ArgTypes[]]) => string;};
'ZipCode': {t: (args?: [...ArgTypes[]]) => string;};
'Phone': {t: (args?: [...ArgTypes[]]) => string;};
'Email': {t: (args?: [...ArgTypes[]]) => string;};
'NickName': {t: (args?: [...ArgTypes[]]) => string;};
'AddressType': {t: (args?: [...ArgTypes[]]) => string;};
'Shipping': {t: (args?: [...ArgTypes[]]) => string;};
'Billing': {t: (args?: [...ArgTypes[]]) => string;};
'ShippingAndBilling': {t: (args?: [...ArgTypes[]]) => string;};
};
'Actions': {'Cancel': {t: (args?: [...ArgTypes[]]) => string;};
'SaveChanges': {t: (args?: [...ArgTypes[]]) => string;};
'CreateAddress': {t: (args?: [...ArgTypes[]]) => string;};
'SaveAndSelect': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'ChangePassword': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'CurrentPasswordLabel': {t: (args?: [...ArgTypes[]]) => string;};
'NewPasswordLabel': {t: (args?: [...ArgTypes[]]) => string;};
'VerifyPasswordLabel': {t: (args?: [...ArgTypes[]]) => string;};
'SaveLabel': {t: (args?: [...ArgTypes[]]) => string;};
'CancelLabel': {t: (args?: [...ArgTypes[]]) => string;};
'SuccessLabel': {t: (args?: [...ArgTypes[]]) => string;};
'OkLabel': {t: (args?: [...ArgTypes[]]) => string;};
};
'CheckoutProfile': {'ShippingInformation': {t: (args?: [...ArgTypes[]]) => string;};
'CreateCheckoutTitle': {t: (args?: [...ArgTypes[]]) => string;};
'CreateCheckoutButton': {t: (args?: [...ArgTypes[]]) => string;};
'BillingInformation': {t: (args?: [...ArgTypes[]]) => string;};
'PaymentInformation': {t: (args?: [...ArgTypes[]]) => string;};
'ShippingMethod': {t: (args?: [...ArgTypes[]]) => string;};
'ShipSameAsBill': {t: (args?: [...ArgTypes[]]) => string;};
'Cancel': {t: (args?: [...ArgTypes[]]) => string;};
'Next': {t: (args?: [...ArgTypes[]]) => string;};
'Back': {t: (args?: [...ArgTypes[]]) => string;};
'CreateProfile': {t: (args?: [...ArgTypes[]]) => string;};
'UpdateProfile': {t: (args?: [...ArgTypes[]]) => string;};
'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Label': {t: (args?: [...ArgTypes[]]) => string;};
'MyAcctDesc': {t: (args?: [...ArgTypes[]]) => string;};
'Name': {t: (args?: [...ArgTypes[]]) => string;};
'Edit': {t: (args?: [...ArgTypes[]]) => string;};
'Delete': {t: (args?: [...ArgTypes[]]) => string;};
'NoneFound': {t: (args?: [...ArgTypes[]]) => string;};
'NoProfiles': {t: (args?: [...ArgTypes[]]) => string;};
'TitleCreate': {t: (args?: [...ArgTypes[]]) => string;};
'CreateMessage': {t: (args?: [...ArgTypes[]]) => string;};
'CreateNewButton': {t: (args?: [...ArgTypes[]]) => string;};
'ShippingAddr': {t: (args?: [...ArgTypes[]]) => string;};
'ShippingMethodDisp': {t: (args?: [...ArgTypes[]]) => string;};
'BillingAddr': {t: (args?: [...ArgTypes[]]) => string;};
'BillingMethod': {t: (args?: [...ArgTypes[]]) => string;};
'EditProfile': {t: (args?: [...ArgTypes[]]) => string;};
'ShipAsComplete': {t: (args?: [...ArgTypes[]]) => string;};
'confirmDelete': {t: (args?: [...ArgTypes[]]) => string;};
'selectExisting': {t: (args?: [...ArgTypes[]]) => string;};
'sm_Express': {t: (args?: [...ArgTypes[]]) => string;};
'sm_Mail': {t: (args?: [...ArgTypes[]]) => string;};
'sm_InternationalPriority': {t: (args?: [...ArgTypes[]]) => string;};
'sm_InternationalRegular': {t: (args?: [...ArgTypes[]]) => string;};
'sm_PickupInStore': {t: (args?: [...ArgTypes[]]) => string;};
'saveAddress': {t: (args?: [...ArgTypes[]]) => string;};
'invalidProfile': {t: (args?: [...ArgTypes[]]) => string;};
'invalidProfileName': {t: (args?: [...ArgTypes[]]) => string;};
'selectExpMonth': {t: (args?: [...ArgTypes[]]) => string;};
'selectExpYear': {t: (args?: [...ArgTypes[]]) => string;};
'none': {t: (args?: [...ArgTypes[]]) => string;};
'selectShippingMethod': {t: (args?: [...ArgTypes[]]) => string;};
'selectPaymentMethod': {t: (args?: [...ArgTypes[]]) => string;};
'payMethods': {'VISA': {t: (args?: [...ArgTypes[]]) => string;};
'MasterCard': {t: (args?: [...ArgTypes[]]) => string;};
'AMEX': {t: (args?: [...ArgTypes[]]) => string;};
'COD': {t: (args?: [...ArgTypes[]]) => string;};
'ApplePay': {t: (args?: [...ArgTypes[]]) => string;};
'BillMeLater': {t: (args?: [...ArgTypes[]]) => string;};
'Check': {t: (args?: [...ArgTypes[]]) => string;};
'PayInStore': {t: (args?: [...ArgTypes[]]) => string;};
'PayLater': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'ChildPimCategories': {'title': {t: ({name}: TemplateArgs) => string;};
};
'Confirmation': {'ScheduleOrderCancel': {t: (args?: [...ArgTypes[]]) => string;};
'ScheduleOrderCancelNotification': {t: ({next}: TemplateArgs) => string;};
'CancelRecurringOrder': {t: (args?: [...ArgTypes[]]) => string;};
'SubmitButton': {t: (args?: [...ArgTypes[]]) => string;};
'CancelButton': {t: (args?: [...ArgTypes[]]) => string;};
};
'FeaturedCard': {'ShopNow': {t: (args?: [...ArgTypes[]]) => string;};
};
'ForgotPassword': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'EmailLabel': {t: (args?: [...ArgTypes[]]) => string;};
'EmailPlaceholder': {t: (args?: [...ArgTypes[]]) => string;};
'SubmitButton': {t: (args?: [...ArgTypes[]]) => string;};
'LogonIDLabel': {t: (args?: [...ArgTypes[]]) => string;};
'ContentText': {t: (args?: [...ArgTypes[]]) => string;};
'ContentTextLogonID': {t: (args?: [...ArgTypes[]]) => string;};
'ValidationCodeButton': {t: (args?: [...ArgTypes[]]) => string;};
'SendVerificationCodeButton': {t: (args?: [...ArgTypes[]]) => string;};
'CodeReceived': {t: (args?: [...ArgTypes[]]) => string;};
'AccountInfoRemember': {t: (args?: [...ArgTypes[]]) => string;};
'SignIn': {t: (args?: [...ArgTypes[]]) => string;};
'Msgs': {'InvalidFormat': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'PriceDisplay': {'Labels': {'Pending': {t: (args?: [...ArgTypes[]]) => string;};
'PerUnit': {t: ({price}: TemplateArgs) => string;};
};
};
'error-message': {'ViewCart': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_2020': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_2050': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_2070': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_5060': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_5066': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_5210': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_2190': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_2080': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_LOGONID_ALREDY_EXIST_2030': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_PASSWORDS_NOT_SAME_2080': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_USER_ALREADY_REGISTERD': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_AUTHENTICATION_MINIMUMLENGTH_PASSWORD_2200': {t: (args: [ArgTypes, ArgTypes, ArgTypes, ...ArgTypes[]]) => string;};
'_ERR_AUTHENTICATION_MAXCONSECUTIVECHAR_PASSWORD_2210': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'_ERR_AUTHENTICATION_MAXINTANCECHAR_PASSWORD_2220': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'_ERR_AUTHENTICATION_MINIMUMLETTERS_PASSWORD_2230': {t: (args: [ArgTypes, ArgTypes, ArgTypes, ...ArgTypes[]]) => string;};
'_ERR_AUTHENTICATION_MINIMUMDIGITS_PASSWORD_2240': {t: (args: [ArgTypes, ArgTypes, ArgTypes, ...ArgTypes[]]) => string;};
'_ERR_AUTHENTICATION_USERIDMATCH_PASSWORD_2250': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_AUTHENTICATION_REUSEOLD_PASSWORD_2260': {t: (args?: [...ArgTypes[]]) => string;};
'_2040': {t: (args?: [...ArgTypes[]]) => string;};
'_2060': {t: (args?: [...ArgTypes[]]) => string;};
'_2070': {t: (args?: [...ArgTypes[]]) => string;};
'_2200': {t: (args: [ArgTypes, ArgTypes, ArgTypes, ...ArgTypes[]]) => string;};
'_2210': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'_2220': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'_2230': {t: (args: [ArgTypes, ArgTypes, ArgTypes, ...ArgTypes[]]) => string;};
'_2240': {t: (args: [ArgTypes, ArgTypes, ArgTypes, ...ArgTypes[]]) => string;};
'_ERR_BAD_PARMS_2050': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_BAD_PARMS_2080': {t: (args?: [...ArgTypes[]]) => string;};
'_2250': {t: (args?: [...ArgTypes[]]) => string;};
'_2260': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_AUTHENTICATION_ERROR_2000': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_AUTHENTICATION_ERROR_2010': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_AUTHENTICATION_ERROR_2020': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_AUTHENTICATION_ERROR_2030': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_PASSWORD_FAILED_CMN1019E': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_PERSON_ACCOUNT_DISABLED_2110': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_PERSON_ACCOUNT_LOCKED_OUT_2490': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'_ERR_LOGIN_NOT_ALLOWED_NOW_2300': {t: (args?: [...ArgTypes[]]) => string;};
'_2400': {t: (args?: [...ArgTypes[]]) => string;};
'_2410': {t: (args?: [...ArgTypes[]]) => string;};
'_2420': {t: (args?: [...ArgTypes[]]) => string;};
'_2450': {t: (args?: [...ArgTypes[]]) => string;};
'_2430': {t: (args?: [...ArgTypes[]]) => string;};
'_2570': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_GENERIC_2110': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_MISSING_PARMS_2000': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_MISSING_PARMS_2010': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_BAD_PARMS_2010': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_MISSING_PARAM_2020': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_MISSING_PARMS_2040': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_MISSING_PARMS_2060': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_MISSING_PARMS_2070': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_MISSING_PARMS_2100': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_BAD_PARMS_1020': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_5080': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_5100': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_5120': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_5140': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_5160': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_5030': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_NICKNAME_ALREDY_EXIST_5040': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'_ERR_CMD_MISSING_PARAM_2000': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_2010': {t: (args?: [...ArgTypes[]]) => string;};
'ERR_PROMOTION_CODE_INVALID_-1200': {t: (args?: [...ArgTypes[]]) => string;};
'ERR_PROMOTION_NOT_AVAILABLE_AT_THIS_TIME_-2200': {t: (args?: [...ArgTypes[]]) => string;};
'ERR_PROMOTION_PER_SHOPPER_LIMIT_EXCEEDED_-1800': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'ERR_PROMOTION_OVERALL_LIMIT_EXCEEDED_-1900': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'ERR_PROMOTION_SYSTEM_ERR_-2700': {t: (args?: [...ArgTypes[]]) => string;};
'ERR_PROMOTION_CODE_DUPLICATED_-1150': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'ERR_PROMOTION_UNKNOWN_ERR_-2600': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_FORMAT_PROMOCODES_NOT_CORRECT_-2820': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_PARAMETER_MISSING_-2810': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_FORMAT_ORDERIDS_NOT_CORRECT_-2830': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_PAY_CARD_NUMBER_MISSING_190': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_PAY_CARD_BRAND_MISSING_190': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CREATE_PAYMENT_METHOD': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CREATE_PAYMENT_METHOD_1007': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_PAY_CARD_NUMBER_INVALID_1005': {t: (args?: [...ArgTypes[]]) => string;};
'_DBG_API_PAY_BAD_LEN_OR_PREFIX_1005': {t: (args?: [...ArgTypes[]]) => string;};
'_DBG_API_DO_PAYMENT_BAD_XDATE_1006': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_PAY_EXPIRY_DATE_INVALID_1006': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CREATE_PAYMENT_METHOD_CMN1112E': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_RDN_ALREADY_EXIST': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_parentMemberId': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_parentMember': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_org_orgEntityName': {t: (args?: [...ArgTypes[]]) => string;};
'_API_BAD_INV_2': {t: (args: [ArgTypes, ArgTypes, ...ArgTypes[]]) => string;};
'_API_BAD_INV_DELIVERY': {t: (args?: [...ArgTypes[]]) => string;};
'_API_BAD_INV_PICKUP': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_FINDING_ORDER_230': {t: (args?: [...ArgTypes[]]) => string;};
'ERR_INTERNAL_SERVER_ERROR_CWXFR0230E': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_REMOTE_EXCEPTION_CMN0412E': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_REG_DUPE_ID_CMN0114S': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_FINDER_EXCEPTION_CMN0411E': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CMD_INVALID_PARAM_2195': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_PASSWORD_EXPIRED_2170': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_INVALID_NAME_CMN1652E': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'_ERR_GOOGLE_MAP_NOT_SUPPORT': {t: (args?: [...ArgTypes[]]) => string;};
'HTTP_404_NOT_FOUND': {t: (args?: [...ArgTypes[]]) => string;};
'HTTP_500_INTERNAL_SERVER_ERROR': {t: (args?: [...ArgTypes[]]) => string;};
'NoCopyOrderItems': {t: (args?: [...ArgTypes[]]) => string;};
'_ERR_CATALOG_ENTRY_MARK_FOR_DELETE_CWXCO3006E': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
};
'success-message': {'ITEM_ADD_SUCCESS': {t: (args?: [...ArgTypes[]]) => string;};
'ViewCart': {t: (args?: [...ArgTypes[]]) => string;};
'COPY_CART_SUCCESS': {t: (args?: [...ArgTypes[]]) => string;};
'RecurringOrderCancelled': {t: (args?: [...ArgTypes[]]) => string;};
'ADD_ADDRESS_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'EDIT_ADDRESS_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'DELETE_ADDRESS_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'RESEND_VERIFICATION_CODE': {t: (args?: [...ArgTypes[]]) => string;};
'PASSWORD_RESET_SUCCESS': {t: (args?: [...ArgTypes[]]) => string;};
'CREATE_CHECKOUT_PROFILE_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'COPY_ORDER_SUCCESS': {t: (args?: [...ArgTypes[]]) => string;};
'CREATE_ORDER_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'DELETE_ORDER_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'CREATE_REQUISITIONLIST_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'DELETE_REQUISITIONLIST_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'UPDATED_REQUISITIONLIST_SUCCESS': {t: (args?: [...ArgTypes[]]) => string;};
'DeletedCheckoutProfile': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'UpdatedCheckoutProfile': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'deletedSuccessfully': {t: ({n}: TemplateArgs) => string;};
'addedItemSuccessfully': {t: ({v}: TemplateArgs) => string;};
'addedNSuccessfully': {t: ({v}: TemplateArgs) => string;};
'addedItemRLSuccessfully': {t: ({v}: TemplateArgs) => string;};
'addedNRLSuccessfully': {t: ({v}: TemplateArgs) => string;};
'deletedItemListSuccessfully': {t: ({count}: TemplateArgs) => string;};
'CREATE_WISHLIST_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'DELETE_WISHLIST_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'DELETE_WISHLIST_ITEM_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'DELETE_WISHLIST_ITEMS_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'WISHLIST_ADD_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'addItemListSuccessfully': {t: ({v}: TemplateArgs) => string;};
'addNItemsSuc': {t: ({n}: TemplateArgs) => string;};
'UPDATE_WISHLIST_NAME_SUCCESS': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'ITEM_TO_CART': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'ITEMS_N_TO_CART': {t: (args: [ArgTypes, ...ArgTypes[]]) => string;};
'CopyOrder': {t: (args?: [...ArgTypes[]]) => string;};
'PartialCopyOrder': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'userAccountEnabled': {t: ({logonId}: TemplateArgs) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'userAccountDisabled': {t: ({logonId}: TemplateArgs) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'userPasswordReset': {t: ({logonId}: TemplateArgs) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'BUYER_APPROVAL_APPROVE_SUCCESS': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'BUYER_APPROVAL_REJECT_SUCCESS': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'ORDER_APPROVAL_APPROVE_SUCCESS': {t: (args?: [...ArgTypes[]]) => string;};
/** @deprecated WARNING Translation Missing in: de-DE & es-ES & fr-FR & it-IT & ja-JP & ko-KR & pl-PL & pt-BR & pt-PT & ro-RO & ru-RU & zh-CN & zh-TW */
                    'ORDER_APPROVAL_REJECT_SUCCESS': {t: (args?: [...ArgTypes[]]) => string;};
};
'OrderBillingInfo': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Labels': {'BillAddress': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'OrderDetails': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Actions': {'Back': {t: (args?: [...ArgTypes[]]) => string;};
'Next': {t: (args?: [...ArgTypes[]]) => string;};
'NextRecurringOrder': {t: (args?: [...ArgTypes[]]) => string;};
'BackCart': {t: (args?: [...ArgTypes[]]) => string;};
};
'Labels': {'PaymentDetails': {t: (args?: [...ArgTypes[]]) => string;};
'OrderSummary': {t: (args?: [...ArgTypes[]]) => string;};
'Empty': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'OrderDiscountSummary': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Labels': {'Total': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'OrderItemTable': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Labels': {'ItemDetails': {t: (args?: [...ArgTypes[]]) => string;};
'SKU': {t: (args?: [...ArgTypes[]]) => string;};
'Availability': {t: (args?: [...ArgTypes[]]) => string;};
'Quantity': {t: (args?: [...ArgTypes[]]) => string;};
'Price': {t: (args?: [...ArgTypes[]]) => string;};
'Actions': {t: (args?: [...ArgTypes[]]) => string;};
'Gift': {t: (args?: [...ArgTypes[]]) => string;};
'NonRecurring': {t: (args?: [...ArgTypes[]]) => string;};
'PageSizeLabel': {t: (args?: [...ArgTypes[]]) => string;};
'FirstPage': {t: (args?: [...ArgTypes[]]) => string;};
'LastPage': {t: (args?: [...ArgTypes[]]) => string;};
'PreviousPage': {t: (args?: [...ArgTypes[]]) => string;};
'NextPage': {t: (args?: [...ArgTypes[]]) => string;};
'RowCount': {t: (args?: [...ArgTypes[]]) => string;};
'showAttrs': {t: (args?: [...ArgTypes[]]) => string;};
'hideAttrs': {t: (args?: [...ArgTypes[]]) => string;};
'Status': {t: (args?: [...ArgTypes[]]) => string;};
'RemoveFromCart': {t: (args?: [...ArgTypes[]]) => string;};
'Empty': {t: (args?: [...ArgTypes[]]) => string;};
'ProductThumbnail': {t: (args?: [...ArgTypes[]]) => string;};
};
'Actions': {'Delete': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'OrderLinks': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'OrderHistoryText': {t: (args?: [...ArgTypes[]]) => string;};
'OrderHistoryDescription': {t: (args?: [...ArgTypes[]]) => string;};
'InprogressOrdersText': {t: (args?: [...ArgTypes[]]) => string;};
'InprogressOrdersDescription': {t: (args?: [...ArgTypes[]]) => string;};
'RecurringOrdersText': {t: (args?: [...ArgTypes[]]) => string;};
'RecurringOrdersDescription': {t: (args?: [...ArgTypes[]]) => string;};
'ApproveOrdersText': {t: (args?: [...ArgTypes[]]) => string;};
'ApproveOrdersDescription': {t: (args?: [...ArgTypes[]]) => string;};
'RequisitionListsText': {t: (args?: [...ArgTypes[]]) => string;};
'RequisitionListsDescription': {t: (args?: [...ArgTypes[]]) => string;};
};
'OrderPaymentInfo': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Labels': {'BillMethod': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'OrderShippingInfo': {'Labels': {'ShipAddress': {t: (args?: [...ArgTypes[]]) => string;};
'ShipMethod': {t: (args?: [...ArgTypes[]]) => string;};
'CartDetails': {t: (args?: [...ArgTypes[]]) => string;};
'ShippingDetails': {t: (args?: [...ArgTypes[]]) => string;};
'ShipmentGroup': {t: ({index}: TemplateArgs) => string;};
'HideGroupDetails': {t: (args?: [...ArgTypes[]]) => string;};
'ShowGroupDetails': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'OrderTotalSummary': {'Labels': {'Subtotal': {t: (args?: [...ArgTypes[]]) => string;};
'Tax': {t: (args?: [...ArgTypes[]]) => string;};
'Shipping': {t: (args?: [...ArgTypes[]]) => string;};
'ShippingTax': {t: (args?: [...ArgTypes[]]) => string;};
'Discount': {t: (args?: [...ArgTypes[]]) => string;};
'Total': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'PaymentMethodContainer': {'Actions': {'Cancel': {t: (args?: [...ArgTypes[]]) => string;};
'Done': {t: (args?: [...ArgTypes[]]) => string;};
'SaveAndAdd': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'PaymentInfoCard': {'Title': {t: ({number}: TemplateArgs) => string;};
'Labels': {'CreditCard': {t: (args?: [...ArgTypes[]]) => string;};
'AmountToPay': {t: (args?: [...ArgTypes[]]) => string;};
'ExpiryDate': {t: ({month, year}: TemplateArgs) => string;};
'BillingAddress': {t: (args?: [...ArgTypes[]]) => string;};
};
'Actions': {'Edit': {t: (args?: [...ArgTypes[]]) => string;};
'Delete': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'PaymentInfoList': {'Title': {'PayMethod': {t: (args?: [...ArgTypes[]]) => string;};
'WithOrderTotal': {t: (args?: [...ArgTypes[]]) => string;};
};
'Msgs': {'PayMethodRequired': {t: (args?: [...ArgTypes[]]) => string;};
'InsufficientPayment': {t: ({grandTotal}: TemplateArgs) => string;};
'CardDetailsUpdate': {t: (args?: [...ArgTypes[]]) => string;};
'PayMethodNote': {t: (args?: [...ArgTypes[]]) => string;};
'PaymentAmountError': {t: ({paymentsTotal, grandTotal}: TemplateArgs) => string;};
'notValidForMulti': {t: ({type}: TemplateArgs) => string;};
};
'Actions': {'AddPayMethod': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'PaymentMethodSelection': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Msgs': {'CreditCard': {t: ({cards}: TemplateArgs) => string;};
'CVV': {t: (args?: [...ArgTypes[]]) => string;};
'InvalidCardNumber': {t: (args?: [...ArgTypes[]]) => string;};
'InvalidFormat': {t: (args?: [...ArgTypes[]]) => string;};
'SelectPaymentMethod': {t: (args?: [...ArgTypes[]]) => string;};
};
'Labels': {'COD': {t: (args?: [...ArgTypes[]]) => string;};
'CreditCard': {t: (args?: [...ArgTypes[]]) => string;};
'CCNumber': {t: (args?: [...ArgTypes[]]) => string;};
'ExpiryDate': {t: (args?: [...ArgTypes[]]) => string;};
'CVV': {t: (args?: [...ArgTypes[]]) => string;};
'OrderTotal': {t: (args?: [...ArgTypes[]]) => string;};
'RemainingAmount': {t: (args?: [...ArgTypes[]]) => string;};
'AmountToPay': {t: (args?: [...ArgTypes[]]) => string;};
'PayTooMuch': {t: (args?: [...ArgTypes[]]) => string;};
'ExpiryMonth': {t: (args?: [...ArgTypes[]]) => string;};
'ExpiryYear': {t: (args?: [...ArgTypes[]]) => string;};
'Choose': {t: (args?: [...ArgTypes[]]) => string;};
};
'Actions': {'PayRemaining': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'PersonalInformation': {'UpdateSuccessful': {t: (args?: [...ArgTypes[]]) => string;};
'AccountInformation': {t: (args?: [...ArgTypes[]]) => string;};
'Edit': {t: (args?: [...ArgTypes[]]) => string;};
'Password': {t: (args?: [...ArgTypes[]]) => string;};
'ChangePassword': {t: (args?: [...ArgTypes[]]) => string;};
'EditAccountInformation': {t: (args?: [...ArgTypes[]]) => string;};
'FirstName': {t: (args?: [...ArgTypes[]]) => string;};
'LastName': {t: (args?: [...ArgTypes[]]) => string;};
'Email': {t: (args?: [...ArgTypes[]]) => string;};
'PhoneNumber': {t: (args?: [...ArgTypes[]]) => string;};
'PreferredCurrency': {t: (args?: [...ArgTypes[]]) => string;};
'Gender': {t: (args?: [...ArgTypes[]]) => string;};
'SelectGender': {t: (args?: [...ArgTypes[]]) => string;};
'BirthYear': {t: (args?: [...ArgTypes[]]) => string;};
'BirthMonth': {t: (args?: [...ArgTypes[]]) => string;};
'BirthDate': {t: (args?: [...ArgTypes[]]) => string;};
'SelectYear': {t: (args?: [...ArgTypes[]]) => string;};
'SelectMonth': {t: (args?: [...ArgTypes[]]) => string;};
'SelectDate': {t: (args?: [...ArgTypes[]]) => string;};
'DateOfBirthEmpty': {t: (args?: [...ArgTypes[]]) => string;};
'Cancel': {t: (args?: [...ArgTypes[]]) => string;};
'SaveChanges': {t: (args?: [...ArgTypes[]]) => string;};
'CurrentPassword': {t: (args?: [...ArgTypes[]]) => string;};
'NewPassword': {t: (args?: [...ArgTypes[]]) => string;};
'VerifyPassword': {t: (args?: [...ArgTypes[]]) => string;};
'SignOutButton': {t: (args?: [...ArgTypes[]]) => string;};
'actingAs': {t: (args?: [...ArgTypes[]]) => string;};
'resetPass': {t: (args?: [...ArgTypes[]]) => string;};
'resetSure': {t: (args?: [...ArgTypes[]]) => string;};
'resetNo': {t: (args?: [...ArgTypes[]]) => string;};
'resetYes': {t: (args?: [...ArgTypes[]]) => string;};
'stopActing': {t: (args?: [...ArgTypes[]]) => string;};
'guestUser': {t: (args?: [...ArgTypes[]]) => string;};
'register': {t: (args?: [...ArgTypes[]]) => string;};
'enableUser': {t: (args?: [...ArgTypes[]]) => string;};
'disableUser': {t: (args?: [...ArgTypes[]]) => string;};
'disabledMessage': {t: (args?: [...ArgTypes[]]) => string;};
};
'ProductFilter': {'Labels': {'filterBy': {t: (args?: [...ArgTypes[]]) => string;};
'noFilters': {t: (args?: [...ArgTypes[]]) => string;};
'price': {t: (args?: [...ArgTypes[]]) => string;};
'minPrice': {t: (args?: [...ArgTypes[]]) => string;};
'maxPrice': {t: (args?: [...ArgTypes[]]) => string;};
};
'Actions': {'showMore': {t: (args?: [...ArgTypes[]]) => string;};
'showLess': {t: (args?: [...ArgTypes[]]) => string;};
'Filter': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'ProductGrid': {'Labels': {'filteredBy': {t: (args?: [...ArgTypes[]]) => string;};
'productFound': {t: ({count}: TemplateArgs) => string;};
'productSearchFound': {t: ({count, searchTerm}: TemplateArgs) => string;};
'noMatches': {t: ({searchTerm}: TemplateArgs) => string;};
'searchAgain': {t: ({searchTerm}: TemplateArgs) => string;};
'suggestion': {t: (args?: [...ArgTypes[]]) => string;};
'noProductsFound': {t: ({keyword}: TemplateArgs) => string;};
'noProductsFoundForFilter': {t: (args?: [...ArgTypes[]]) => string;};
'sortBy': {t: (args?: [...ArgTypes[]]) => string;};
'gtmItemList': {t: (args?: [...ArgTypes[]]) => string;};
'gtmSearchResults': {t: ({term}: TemplateArgs) => string;};
};
'Actions': {'clearAll': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'PurchaseOrderNumber': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Msgs': {'PONumberRequired': {t: (args?: [...ArgTypes[]]) => string;};
};
'Labels': {'PONumber': {t: (args?: [...ArgTypes[]]) => string;};
'display': {t: ({poNumber}: TemplateArgs) => string;};
};
};
'PurchaseOrderSelection': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Msgs': {'InvalidFormat': {t: (args?: [...ArgTypes[]]) => string;};
};
'Labels': {'PONumber': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'RecurringOrderInfo': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Labels': {'RecurringOrderNumber': {t: (args?: [...ArgTypes[]]) => string;};
'OrderSchedule': {t: (args?: [...ArgTypes[]]) => string;};
'StartDate': {t: (args?: [...ArgTypes[]]) => string;};
'NextDelivery': {t: (args?: [...ArgTypes[]]) => string;};
'OrderHistoryButton': {t: ({orderId}: TemplateArgs) => string;};
};
};
'RegistrationLayout': {'NewCustomer': {t: (args?: [...ArgTypes[]]) => string;};
'SignIn': {t: (args?: [...ArgTypes[]]) => string;};
'Account': {t: (args?: [...ArgTypes[]]) => string;};
'FirstName': {t: (args?: [...ArgTypes[]]) => string;};
'LastName': {t: (args?: [...ArgTypes[]]) => string;};
'LogonId': {t: (args?: [...ArgTypes[]]) => string;};
'Email': {t: (args?: [...ArgTypes[]]) => string;};
'emailPlaceholder': {t: (args?: [...ArgTypes[]]) => string;};
'Phone': {t: (args?: [...ArgTypes[]]) => string;};
'Password': {t: (args?: [...ArgTypes[]]) => string;};
'VerifyPassword': {t: (args?: [...ArgTypes[]]) => string;};
'TextContent': {t: (args?: [...ArgTypes[]]) => string;};
'Register': {t: (args?: [...ArgTypes[]]) => string;};
'RegisterComplete': {t: (args?: [...ArgTypes[]]) => string;};
'RememberMe': {t: (args?: [...ArgTypes[]]) => string;};
'Msgs': {'InvalidFormat': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'RegistrationB2BLayout': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Actions': {'BuyerReg': {t: (args?: [...ArgTypes[]]) => string;};
'OrgReg': {t: (args?: [...ArgTypes[]]) => string;};
'AddBuyer': {t: (args?: [...ArgTypes[]]) => string;};
'AddOrg': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'ResetPassword': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'ValidationCodeLabel': {t: (args?: [...ArgTypes[]]) => string;};
'NewPasswordLabel': {t: (args?: [...ArgTypes[]]) => string;};
'VerifyPasswordLabel': {t: (args?: [...ArgTypes[]]) => string;};
'SubmitButton': {t: (args?: [...ArgTypes[]]) => string;};
'ContentText': {t: (args?: [...ArgTypes[]]) => string;};
'ContentTextLogonID': {t: (args?: [...ArgTypes[]]) => string;};
'EmailLabel': {t: (args?: [...ArgTypes[]]) => string;};
'EmailPlaceholder': {t: (args?: [...ArgTypes[]]) => string;};
'LogonIDLabel': {t: (args?: [...ArgTypes[]]) => string;};
'SuccessMsg': {t: (args?: [...ArgTypes[]]) => string;};
'PasswordNotReceived': {t: (args?: [...ArgTypes[]]) => string;};
'ResendVerificationCode': {t: (args?: [...ArgTypes[]]) => string;};
'AccountInfoRemember': {t: (args?: [...ArgTypes[]]) => string;};
'SignIn': {t: (args?: [...ArgTypes[]]) => string;};
'Msgs': {'InvalidFormat': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'SearchBar': {'SearchField': {t: (args?: [...ArgTypes[]]) => string;};
'KeywordTitle': {t: (args?: [...ArgTypes[]]) => string;};
'CategoryTitle': {t: (args?: [...ArgTypes[]]) => string;};
'BrandTitle': {t: (args?: [...ArgTypes[]]) => string;};
'SellerTitle': {t: (args?: [...ArgTypes[]]) => string;};
'Clear': {t: (args?: [...ArgTypes[]]) => string;};
};
'SessionError': {'TimeoutTitle': {t: (args?: [...ArgTypes[]]) => string;};
'InvalidTitle': {t: (args?: [...ArgTypes[]]) => string;};
'GenericTitle': {t: (args?: [...ArgTypes[]]) => string;};
'TimeoutMsg': {t: (args?: [...ArgTypes[]]) => string;};
'InvalidMsg': {t: (args?: [...ArgTypes[]]) => string;};
'InvalidMsgB2C': {t: (args?: [...ArgTypes[]]) => string;};
'Email': {t: (args?: [...ArgTypes[]]) => string;};
'Password': {t: (args?: [...ArgTypes[]]) => string;};
'SubmitButton': {t: (args?: [...ArgTypes[]]) => string;};
'CancelButton': {t: (args?: [...ArgTypes[]]) => string;};
'PartialAuthError': {t: (args?: [...ArgTypes[]]) => string;};
'ActionIncomplete': {t: (args?: [...ArgTypes[]]) => string;};
};
'SignIn': {'ReturningCustomer': {t: (args?: [...ArgTypes[]]) => string;};
'Password': {t: (args?: [...ArgTypes[]]) => string;};
'ChangePassword': {t: (args?: [...ArgTypes[]]) => string;};
'CurrentPassword': {t: (args?: [...ArgTypes[]]) => string;};
'PasswordVerify': {t: (args?: [...ArgTypes[]]) => string;};
'RememberMe': {t: (args?: [...ArgTypes[]]) => string;};
'SignInButton': {t: (args?: [...ArgTypes[]]) => string;};
'SignInAndCheckoutButton': {t: (args?: [...ArgTypes[]]) => string;};
'SignInAndCheckoutTitle': {t: (args?: [...ArgTypes[]]) => string;};
'CheckoutAsGuestMsg': {t: (args?: [...ArgTypes[]]) => string;};
'CheckoutAsGuestButton': {t: (args?: [...ArgTypes[]]) => string;};
'Cancel': {t: (args?: [...ArgTypes[]]) => string;};
'Submit': {t: (args?: [...ArgTypes[]]) => string;};
'ForgotPassword': {t: (args?: [...ArgTypes[]]) => string;};
'noAccount': {t: (args?: [...ArgTypes[]]) => string;};
'registerNow': {t: (args?: [...ArgTypes[]]) => string;};
'emailPlaceholder': {t: (args?: [...ArgTypes[]]) => string;};
'Label': {'LogonId': {t: (args?: [...ArgTypes[]]) => string;};
'Email': {t: (args?: [...ArgTypes[]]) => string;};
'rememberMe': {t: (args?: [...ArgTypes[]]) => string;};
};
'Msgs': {'InvalidFormat': {t: (args?: [...ArgTypes[]]) => string;};
'PasswordNotMatch': {t: (args?: [...ArgTypes[]]) => string;};
'OldPasswordReuse': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'Pickup': {'PickupOrderMsg': {t: (args?: [...ArgTypes[]]) => string;};
'IllPickupRadioLabel': {t: (args?: [...ArgTypes[]]) => string;};
'SomeoneElseRadioLabel': {t: (args?: [...ArgTypes[]]) => string;};
'HowPickupOrderMsg': {t: (args?: [...ArgTypes[]]) => string;};
'EmailMsg': {t: (args?: [...ArgTypes[]]) => string;};
'ContinueMsg': {t: (args?: [...ArgTypes[]]) => string;};
'BackButton': {t: (args?: [...ArgTypes[]]) => string;};
'ProceedPayment': {t: (args?: [...ArgTypes[]]) => string;};
'FirstName': {t: (args?: [...ArgTypes[]]) => string;};
'LastName': {t: (args?: [...ArgTypes[]]) => string;};
'Email': {t: (args?: [...ArgTypes[]]) => string;};
'Phone': {t: (args?: [...ArgTypes[]]) => string;};
'ContinuePickupDetails': {t: (args?: [...ArgTypes[]]) => string;};
'BackToPickupStore': {t: (args?: [...ArgTypes[]]) => string;};
'ContinueCheckout': {t: (args?: [...ArgTypes[]]) => string;};
'PickupPersonFullName': {t: (args?: [...ArgTypes[]]) => string;};
'PickupPersonEmail': {t: (args?: [...ArgTypes[]]) => string;};
'BuyerFullName': {t: (args?: [...ArgTypes[]]) => string;};
'PickupContactTitle': {t: (args?: [...ArgTypes[]]) => string;};
'PickupStoreTitle': {t: (args?: [...ArgTypes[]]) => string;};
'ContactDetailsTitle': {t: (args?: [...ArgTypes[]]) => string;};
'PickupDetailsTitle': {t: (args?: [...ArgTypes[]]) => string;};
};
'CommerceEnvironment': {'listSettings': {'relevance': {t: (args?: [...ArgTypes[]]) => string;};
'brands': {t: (args?: [...ArgTypes[]]) => string;};
'name': {t: (args?: [...ArgTypes[]]) => string;};
'priceLowToHigh': {t: (args?: [...ArgTypes[]]) => string;};
'priceHighToLow': {t: (args?: [...ArgTypes[]]) => string;};
};
'inventoryStatus': {'Available': {t: (args?: [...ArgTypes[]]) => string;};
'Allocated': {t: (args?: [...ArgTypes[]]) => string;};
'Unallocated': {t: (args?: [...ArgTypes[]]) => string;};
'Backordered': {t: (args?: [...ArgTypes[]]) => string;};
'OOS': {t: (args?: [...ArgTypes[]]) => string;};
'NA': {t: (args?: [...ArgTypes[]]) => string;};
};
'inventoryStatusOnline': {'Available': {t: (args?: [...ArgTypes[]]) => string;};
'Allocated': {t: (args?: [...ArgTypes[]]) => string;};
'Unallocated': {t: (args?: [...ArgTypes[]]) => string;};
'Backordered': {t: (args?: [...ArgTypes[]]) => string;};
'OOS': {t: (args?: [...ArgTypes[]]) => string;};
'NA': {t: (args?: [...ArgTypes[]]) => string;};
};
'inventoryStatusStore': {'Available': {t: ({store}: TemplateArgs) => string;};
'Allocated': {t: ({store}: TemplateArgs) => string;};
'Unallocated': {t: ({store}: TemplateArgs) => string;};
'Backordered': {t: ({store}: TemplateArgs) => string;};
'OOS': {t: ({store}: TemplateArgs) => string;};
'NA': {t: ({store}: TemplateArgs) => string;};
};
'recurringOrderFrequency': {'Everyday': {t: (args?: [...ArgTypes[]]) => string;};
'EveryWeek': {t: (args?: [...ArgTypes[]]) => string;};
'EveryTwoWeeks': {t: (args?: [...ArgTypes[]]) => string;};
'EveryThreeWeeks': {t: (args?: [...ArgTypes[]]) => string;};
'EveryFourWeeks': {t: (args?: [...ArgTypes[]]) => string;};
};
'languagePopUp': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'Message': {t: ({language}: TemplateArgs) => string;};
'Yes': {t: (args?: [...ArgTypes[]]) => string;};
'No': {t: (args?: [...ArgTypes[]]) => string;};
};
'currency': {'ROL': {t: (args?: [...ArgTypes[]]) => string;};
'RON': {t: (args?: [...ArgTypes[]]) => string;};
'ARS': {t: (args?: [...ArgTypes[]]) => string;};
'ATS': {t: (args?: [...ArgTypes[]]) => string;};
'AUD': {t: (args?: [...ArgTypes[]]) => string;};
'BEF': {t: (args?: [...ArgTypes[]]) => string;};
'BRL': {t: (args?: [...ArgTypes[]]) => string;};
'CAD': {t: (args?: [...ArgTypes[]]) => string;};
'CNY': {t: (args?: [...ArgTypes[]]) => string;};
'DEM': {t: (args?: [...ArgTypes[]]) => string;};
'ESP': {t: (args?: [...ArgTypes[]]) => string;};
'EUR': {t: (args?: [...ArgTypes[]]) => string;};
'FRF': {t: (args?: [...ArgTypes[]]) => string;};
'GBP': {t: (args?: [...ArgTypes[]]) => string;};
'HKD': {t: (args?: [...ArgTypes[]]) => string;};
'ITL': {t: (args?: [...ArgTypes[]]) => string;};
'JPY': {t: (args?: [...ArgTypes[]]) => string;};
'KRW': {t: (args?: [...ArgTypes[]]) => string;};
'SGD': {t: (args?: [...ArgTypes[]]) => string;};
'TWD': {t: (args?: [...ArgTypes[]]) => string;};
'USD': {t: (args?: [...ArgTypes[]]) => string;};
'ZAR': {t: (args?: [...ArgTypes[]]) => string;};
'FIM': {t: (args?: [...ArgTypes[]]) => string;};
'IEP': {t: (args?: [...ArgTypes[]]) => string;};
'LUF': {t: (args?: [...ArgTypes[]]) => string;};
'NLG': {t: (args?: [...ArgTypes[]]) => string;};
'PTE': {t: (args?: [...ArgTypes[]]) => string;};
'NOK': {t: (args?: [...ArgTypes[]]) => string;};
'SEK': {t: (args?: [...ArgTypes[]]) => string;};
'DKK': {t: (args?: [...ArgTypes[]]) => string;};
'CHF': {t: (args?: [...ArgTypes[]]) => string;};
'PLN': {t: (args?: [...ArgTypes[]]) => string;};
'RUB': {t: (args?: [...ArgTypes[]]) => string;};
'EGP': {t: (args?: [...ArgTypes[]]) => string;};
};
'language': {'-1': {t: (args?: [...ArgTypes[]]) => string;};
'-2': {t: (args?: [...ArgTypes[]]) => string;};
'-3': {t: (args?: [...ArgTypes[]]) => string;};
'-4': {t: (args?: [...ArgTypes[]]) => string;};
'-5': {t: (args?: [...ArgTypes[]]) => string;};
'-6': {t: (args?: [...ArgTypes[]]) => string;};
'-7': {t: (args?: [...ArgTypes[]]) => string;};
'-8': {t: (args?: [...ArgTypes[]]) => string;};
'-10': {t: (args?: [...ArgTypes[]]) => string;};
'-21': {t: (args?: [...ArgTypes[]]) => string;};
'-20': {t: (args?: [...ArgTypes[]]) => string;};
'-9': {t: (args?: [...ArgTypes[]]) => string;};
'-22': {t: (args?: [...ArgTypes[]]) => string;};
'-23': {t: (args?: [...ArgTypes[]]) => string;};
};
};
'RequisitionLists': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'CreateNewRequisitionList': {t: (args?: [...ArgTypes[]]) => string;};
'NewRequisitionName': {t: (args?: [...ArgTypes[]]) => string;};
'CreateList': {t: (args?: [...ArgTypes[]]) => string;};
'PrivateList': {t: (args?: [...ArgTypes[]]) => string;};
'SharedList': {t: (args?: [...ArgTypes[]]) => string;};
'Visibility': {t: (args?: [...ArgTypes[]]) => string;};
'UploadRequisitionList': {t: (args?: [...ArgTypes[]]) => string;};
'DownloadInstructionsWithAnExample': {t: (args?: [...ArgTypes[]]) => string;};
'ViewUploadLogs': {t: (args?: [...ArgTypes[]]) => string;};
'NoViewRecord': {t: (args?: [...ArgTypes[]]) => string;};
'NoRecord': {t: (args?: [...ArgTypes[]]) => string;};
'Browse': {t: (args?: [...ArgTypes[]]) => string;};
'SelectedFile': {t: ({file}: TemplateArgs) => string;};
'Columns': {'ListName': {t: (args?: [...ArgTypes[]]) => string;};
'CreatedBy': {t: (args?: [...ArgTypes[]]) => string;};
'Items': {t: (args?: [...ArgTypes[]]) => string;};
'DateCreated': {t: (args?: [...ArgTypes[]]) => string;};
'LastUpdate': {t: (args?: [...ArgTypes[]]) => string;};
'Type': {t: (args?: [...ArgTypes[]]) => string;};
'Actions': {t: (args?: [...ArgTypes[]]) => string;};
};
'CreatedBy': {t: ({firstName, lastName}: TemplateArgs) => string;};
'Type': {'Y': {t: (args?: [...ArgTypes[]]) => string;};
'Z': {t: (args?: [...ArgTypes[]]) => string;};
};
'DeleteDialogTitle': {t: (args?: [...ArgTypes[]]) => string;};
'DeleteDialogHeading': {t: (args?: [...ArgTypes[]]) => string;};
'CopyTitle': {t: (args?: [...ArgTypes[]]) => string;};
'CopyMessage': {t: (args?: [...ArgTypes[]]) => string;};
'InvalidListName': {t: (args?: [...ArgTypes[]]) => string;};
'searchPh': {t: (args?: [...ArgTypes[]]) => string;};
'SuccessfullyCreatedRequisitionList': {t: ({name}: TemplateArgs) => string;};
'UploadLog': {t: (args?: [...ArgTypes[]]) => string;};
'UploadedFile': {t: (args?: [...ArgTypes[]]) => string;};
'Status': {t: (args?: [...ArgTypes[]]) => string;};
'UploadTime': {t: (args?: [...ArgTypes[]]) => string;};
'ViewLog': {t: (args?: [...ArgTypes[]]) => string;};
'UpdNofM': {t: ({n, m}: TemplateArgs) => string;};
'PartialCompleteStatusTop': {t: (args?: [...ArgTypes[]]) => string;};
'FailedStatusTop': {t: (args?: [...ArgTypes[]]) => string;};
'PartialCompleteStatusBottom': {t: (args?: [...ArgTypes[]]) => string;};
'FailedStatusBottom': {t: (args?: [...ArgTypes[]]) => string;};
'LineNumber': {t: (args?: [...ArgTypes[]]) => string;};
'SKU': {t: (args?: [...ArgTypes[]]) => string;};
'PartiallyUploaded': {t: (args?: [...ArgTypes[]]) => string;};
'Processing': {t: (args?: [...ArgTypes[]]) => string;};
'Uploaded': {t: (args?: [...ArgTypes[]]) => string;};
'UploadFailed': {t: (args?: [...ArgTypes[]]) => string;};
'search': {t: (args?: [...ArgTypes[]]) => string;};
'Add': {t: (args?: [...ArgTypes[]]) => string;};
'SKUSearch': {t: (args?: [...ArgTypes[]]) => string;};
'Quantity': {t: (args?: [...ArgTypes[]]) => string;};
'NoSKUFound': {t: (args?: [...ArgTypes[]]) => string;};
'EmptyRL': {t: (args?: [...ArgTypes[]]) => string;};
'Refresh': {t: (args?: [...ArgTypes[]]) => string;};
'CopyList': {t: (args?: [...ArgTypes[]]) => string;};
'DeleteList': {t: (args?: [...ArgTypes[]]) => string;};
'nameForCopiedList': {t: (args?: [...ArgTypes[]]) => string;};
'AddListToCart': {t: (args?: [...ArgTypes[]]) => string;};
'SelectUploadFile': {t: (args?: [...ArgTypes[]]) => string;};
};
'RequisitionListItems': {'noItems': {t: (args?: [...ArgTypes[]]) => string;};
'createdBy': {t: (args?: [...ArgTypes[]]) => string;};
'Visibility': {t: (args?: [...ArgTypes[]]) => string;};
'orderType_SRL': {t: (args?: [...ArgTypes[]]) => string;};
'orderType_PRL': {t: (args?: [...ArgTypes[]]) => string;};
'each': {t: (args?: [...ArgTypes[]]) => string;};
'orderItem': {t: (args?: [...ArgTypes[]]) => string;};
'manufacturer': {t: (args?: [...ArgTypes[]]) => string;};
'quantity': {t: (args?: [...ArgTypes[]]) => string;};
'actions': {t: (args?: [...ArgTypes[]]) => string;};
'selectAllProducts': {t: (args?: [...ArgTypes[]]) => string;};
'editListDetails': {t: (args?: [...ArgTypes[]]) => string;};
'addSelectedToCart': {t: (args?: [...ArgTypes[]]) => string;};
'deleteSelected': {t: (args?: [...ArgTypes[]]) => string;};
'nProductsSel': {t: ({n}: TemplateArgs) => string;};
'addProds': {t: (args?: [...ArgTypes[]]) => string;};
'cancel': {t: (args?: [...ArgTypes[]]) => string;};
'saveChanges': {t: (args?: [...ArgTypes[]]) => string;};
'addListToCart': {t: (args?: [...ArgTypes[]]) => string;};
'addToCart': {t: (args?: [...ArgTypes[]]) => string;};
'deleteItem': {t: (args?: [...ArgTypes[]]) => string;};
'DeleteDialogTitle': {t: (args?: [...ArgTypes[]]) => string;};
'DeleteDialogHeading': {t: (args?: [...ArgTypes[]]) => string;};
'Confirm': {t: (args?: [...ArgTypes[]]) => string;};
'Cancel': {t: (args?: [...ArgTypes[]]) => string;};
'Select': {t: (args?: [...ArgTypes[]]) => string;};
'ShowAttributes': {t: (args?: [...ArgTypes[]]) => string;};
'HideAttributes': {t: (args?: [...ArgTypes[]]) => string;};
};
'InprogressOrders': {'Title': {t: (args?: [...ArgTypes[]]) => string;};
'CreateNewOrder': {t: (args?: [...ArgTypes[]]) => string;};
'NewOrderName': {t: (args?: [...ArgTypes[]]) => string;};
'CreateOrder': {t: (args?: [...ArgTypes[]]) => string;};
'PrivateOrder': {t: (args?: [...ArgTypes[]]) => string;};
'SharedOrder': {t: (args?: [...ArgTypes[]]) => string;};
'Columns': {'Active': {t: (args?: [...ArgTypes[]]) => string;};
'OrderId': {t: (args?: [...ArgTypes[]]) => string;};
'Name': {t: (args?: [...ArgTypes[]]) => string;};
'LastUpdated': {t: (args?: [...ArgTypes[]]) => string;};
'TotalPrice': {t: (args?: [...ArgTypes[]]) => string;};
'Type': {t: (args?: [...ArgTypes[]]) => string;};
'Actions': {t: (args?: [...ArgTypes[]]) => string;};
};
'Status': {'ORD': {t: (args?: [...ArgTypes[]]) => string;};
'SHR': {t: (args?: [...ArgTypes[]]) => string;};
};
'DeleteDialogTitle': {t: (args?: [...ArgTypes[]]) => string;};
'DeleteDialogHeading': {t: (args?: [...ArgTypes[]]) => string;};
'CopyTitle': {t: (args?: [...ArgTypes[]]) => string;};
'CopyMessage': {t: (args?: [...ArgTypes[]]) => string;};
'CurrentCart': {t: ({orderId}: TemplateArgs) => string;};
'InvalidOrderName': {t: (args?: [...ArgTypes[]]) => string;};
'searchPh': {t: (args?: [...ArgTypes[]]) => string;};
'NotActiveOrderMessage': {t: (args?: [...ArgTypes[]]) => string;};
};
'InprogressItems': {'orderItem': {t: (args?: [...ArgTypes[]]) => string;};
'creationDate': {t: (args?: [...ArgTypes[]]) => string;};
'quantity': {t: (args?: [...ArgTypes[]]) => string;};
'contributor': {t: (args?: [...ArgTypes[]]) => string;};
'each': {t: (args?: [...ArgTypes[]]) => string;};
'actions': {t: (args?: [...ArgTypes[]]) => string;};
'inprogressOrders': {t: (args?: [...ArgTypes[]]) => string;};
'createdBy': {t: (args?: [...ArgTypes[]]) => string;};
'lastUpdated': {t: (args?: [...ArgTypes[]]) => string;};
'totalPrice': {t: (args?: [...ArgTypes[]]) => string;};
'visibility': {t: (args?: [...ArgTypes[]]) => string;};
'orderType_SHR': {t: (args?: [...ArgTypes[]]) => string;};
'orderType_ORD': {t: (args?: [...ArgTypes[]]) => string;};
'proceed': {t: (args?: [...ArgTypes[]]) => string;};
'addProds': {t: (args?: [...ArgTypes[]]) => string;};
'privateList': {t: (args?: [...ArgTypes[]]) => string;};
'sharedList': {t: (args?: [...ArgTypes[]]) => string;};
'nProductsSel': {t: ({n}: TemplateArgs) => string;};
'addSelToCart': {t: (args?: [...ArgTypes[]]) => string;};
'deleteSelected': {t: (args?: [...ArgTypes[]]) => string;};
'noItems': {t: (args?: [...ArgTypes[]]) => string;};
'nameForCopiedOrder': {t: (args?: [...ArgTypes[]]) => string;};
};
'commonTable': {'ofTotalCount': {t: ({from, to, total}: TemplateArgs) => string;};
'pageSz': {'five': {t: (args?: [...ArgTypes[]]) => string;};
'ten': {t: (args?: [...ArgTypes[]]) => string;};
'twenty': {t: (args?: [...ArgTypes[]]) => string;};
};
'rows': {t: (args?: [...ArgTypes[]]) => string;};
'paginationText': {t: ({from, total}: TemplateArgs) => string;};
};
'WishList': {'CreateWishListTitle': {t: (args?: [...ArgTypes[]]) => string;};
'CreateList': {t: (args?: [...ArgTypes[]]) => string;};
'WishListName': {t: (args?: [...ArgTypes[]]) => string;};
'Title': {t: (args?: [...ArgTypes[]]) => string;};
'NoWishListMessage': {t: (args?: [...ArgTypes[]]) => string;};
'WishListEmptyMessage': {t: (args?: [...ArgTypes[]]) => string;};
'ViewList': {t: (args?: [...ArgTypes[]]) => string;};
'WishListItemsMessage': {t: (args?: [...ArgTypes[]]) => string;};
'InvalidWishListName': {t: (args?: [...ArgTypes[]]) => string;};
'Confirm': {t: (args?: [...ArgTypes[]]) => string;};
'ConfirmDelete': {t: (args?: [...ArgTypes[]]) => string;};
'Cancel': {t: (args?: [...ArgTypes[]]) => string;};
'RemoveItemsTitle': {t: (args?: [...ArgTypes[]]) => string;};
'ConfirmDeleteSelectedMsg': {t: (args?: [...ArgTypes[]]) => string;};
'ConfirmDeleteMsg': {t: (args?: [...ArgTypes[]]) => string;};
'ConfirmDeleteTitle': {t: (args?: [...ArgTypes[]]) => string;};
'EmptyWishListMsg': {t: (args?: [...ArgTypes[]]) => string;};
'Actions': {'AddToCart': {t: (args?: [...ArgTypes[]]) => string;};
'SelectAll': {t: (args?: [...ArgTypes[]]) => string;};
'EditList': {t: (args?: [...ArgTypes[]]) => string;};
'Delete': {t: (args?: [...ArgTypes[]]) => string;};
'Save': {t: (args?: [...ArgTypes[]]) => string;};
'AddSelectedToCart': {t: (args?: [...ArgTypes[]]) => string;};
'DeletedSelected': {t: (args?: [...ArgTypes[]]) => string;};
'DeleteList': {t: (args?: [...ArgTypes[]]) => string;};
};
'ProductSelected': {t: ({n}: TemplateArgs) => string;};
};
'OrderMethod': {'Message': {t: (args?: [...ArgTypes[]]) => string;};
'Delivery': {t: (args?: [...ArgTypes[]]) => string;};
'Pickup': {t: (args?: [...ArgTypes[]]) => string;};
};
'compare': {'compare': {t: (args?: [...ArgTypes[]]) => string;};
'start': {t: ({n}: TemplateArgs) => string;};
'tnail': {t: (args?: [...ArgTypes[]]) => string;};
'notavl': {t: (args?: [...ArgTypes[]]) => string;};
'price': {t: (args?: [...ArgTypes[]]) => string;};
'tabletOrDesk': {t: (args?: [...ArgTypes[]]) => string;};
'goBack': {t: (args?: [...ArgTypes[]]) => string;};
'addAnother': {t: (args?: [...ArgTypes[]]) => string;};
'prodComp': {t: (args?: [...ArgTypes[]]) => string;};
'boxNofM': {t: ({n, m}: TemplateArgs) => string;};
'clearSel': {t: (args?: [...ArgTypes[]]) => string;};
'hide': {t: (args?: [...ArgTypes[]]) => string;};
'expand': {t: (args?: [...ArgTypes[]]) => string;};
'compSel': {t: (args?: [...ArgTypes[]]) => string;};
'addAtLeast': {t: (args?: [...ArgTypes[]]) => string;};
};
'sellers': {'seller': {t: (args?: [...ArgTypes[]]) => string;};
'edit': {t: (args?: [...ArgTypes[]]) => string;};
'select': {t: (args?: [...ArgTypes[]]) => string;};
'mp': {t: (args?: [...ArgTypes[]]) => string;};
'emMp': {t: ({store}: TemplateArgs) => string;};
'fBy': {t: (args?: [...ArgTypes[]]) => string;};
};
'StoreLocator': {'title': {t: (args?: [...ArgTypes[]]) => string;};
'home': {t: (args?: [...ArgTypes[]]) => string;};
'searchStore': {t: (args?: [...ArgTypes[]]) => string;};
'findNearestStore': {t: (args?: [...ArgTypes[]]) => string;};
'selected': {t: (args?: [...ArgTypes[]]) => string;};
'setAsMyStore': {t: (args?: [...ArgTypes[]]) => string;};
'hide': {t: (args?: [...ArgTypes[]]) => string;};
'expand': {t: (args?: [...ArgTypes[]]) => string;};
'clearSearch': {t: (args?: [...ArgTypes[]]) => string;};
'searchResults': {t: ({n, p}: TemplateArgs) => string;};
'searchResultsNearYou': {t: ({n}: TemplateArgs) => string;};
'getDirections': {t: (args?: [...ArgTypes[]]) => string;};
'stopDirections': {t: (args?: [...ArgTypes[]]) => string;};
'searchAPickUpStore': {t: (args?: [...ArgTypes[]]) => string;};
'noResults': {t: ({p}: TemplateArgs) => string;};
'noResultsNearYou': {t: (args?: [...ArgTypes[]]) => string;};
'locateStore': {t: (args?: [...ArgTypes[]]) => string;};
'directionNotAvailable': {t: (args?: [...ArgTypes[]]) => string;};
'distanceKM': {t: ({distance}: TemplateArgs) => string;};
};
'ProgressIndicator': {'label': {t: (args?: [...ArgTypes[]]) => string;};
};
'Files': {'Download': {t: (args?: [...ArgTypes[]]) => string;};
};
'PasswordInput': {'toggleLabel': {t: (args?: [...ArgTypes[]]) => string;};
};
'Common': {'decrement': {t: (args?: [...ArgTypes[]]) => string;};
'increment': {t: (args?: [...ArgTypes[]]) => string;};
'quantity': {t: ({n}: TemplateArgs) => string;};
'navHomeWithMessage': {t: ({message}: TemplateArgs) => string;};
};

}

export const requestTranslation = async ({
    locale,
    section,
}: {
    locale: Languages | string;
    section: Sections | string;
}) => {
    if (!manifest[locale] || typeof manifest[locale][section] !== 'function') {
        return {};
    }
    const loaded = await manifest[locale][section]();
    return JSON.parse(JSON.stringify(loaded.default)) as Translation;
};
